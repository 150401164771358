import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { UsuarioService } from '../SICSv2/usuario/services/usuario.service';
import { Router } from '@angular/router';
import { Pages } from '../shared/models/pages';
import { Sistema } from './models/constants';

@Injectable()
export class IdleService {

    constructor(private idle: Idle,
                private keepalive: Keepalive,
                private usuarioService: UsuarioService,
                private router: Router) {
        this.setup()
    }

    reset() {
        this.idle.watch();
    }

    returnPage() {
        let url = this.getReturnPage();

        if (url) {
            this.router.navigate([url]);
            return true;
        }

        return false;
    }

    setReturnPage(url: string) {
        localStorage.setItem(Sistema.localStorage.urlReturn, url);
    }

    getReturnPage() {
        return localStorage.getItem(Sistema.localStorage.urlReturn);
    }

    private setup() {
        this.idle.setIdle(6 * 60000);
        this.idle.setTimeout(10);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onTimeout.subscribe(() => this.timeout());

        this.keepalive.interval(15 * 60000);
        this.keepalive.onPing.subscribe(() => this.refreshToken());
    }

    private timeout() {
        let urlReturn = this.getReturnPage();
        this.usuarioService.logout();
        this.setReturnPage(urlReturn);
        this.router.navigate([Pages.Usuario.login]);
    }

    private refreshToken() {
      
    }
}