// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descUsuario {
  width: 100%;
  height: auto;
  border: 2px solid #14142B;
  border-radius: 16px;
  border-radius: 16px;
  /*overflow-y: scroll; */
}

.lightblue {
  background-color: #dcf1fa !important;
}

.disable {
  pointer-events: none;
}`, "",{"version":3,"sources":["webpack://./src/app/SICSv2/atribuir-permissao-por-aplicacao/atribuir-permissao-por-aplicacao.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EAGA,mBAAA;EACA,uBAAA;AACJ;;AAEE;EACE,oCAAA;AACJ;;AAEE;EACE,oBAAA;AACJ","sourcesContent":[".descUsuario {\r\n    width: 100%;\r\n    height: auto;\r\n    border: 2px solid #14142B;\r\n    border-radius: 16px;\r\n    -moz-border-radius: 16px;\r\n    -webkit-border-radius: 16px;\r\n    border-radius: 16px;\r\n    /*overflow-y: scroll; */ \r\n  }\r\n\r\n  .lightblue{\r\n    background-color:#dcf1fa !important;\r\n  }\r\n\r\n  .disable {\r\n    pointer-events:none;\r\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
