import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioAdService } from '../desativar-usuarios-ad/Services/usuario-ad.service';
import { Funcionario } from '../models/funcionario';
import { Pages } from 'src/app/shared/models/pages';
import { MessageService } from 'src/app/services/message.service';
import { Mensagem } from 'src/app/shared/models/mensagem';
import { Router } from '@angular/router';
import { Claim } from '../usuario/models/claim';
import { UsuarioService } from '../usuario/services/usuario.service';

@Component({
  selector: 'app-consultar-usuarios-ad',
  templateUrl: './consultar-usuarios-ad.component.html',
  styleUrls: ['./consultar-usuarios-ad.component.scss'],
})
export class ConsultarUsuariosAdComponent implements OnInit {

  funcionariosAd = new Array<Funcionario>();
  submitted = false;
  
  permissoesUsuario: Claim[];

  // grid
  displayedColumns = ['nmFuncionario', 'nuMatricula', 'nmLogin', 'dsCargo', 'dsSetor', 'stSICSAtivoAD'];
  dataSource = new MatTableDataSource<Funcionario>(this.funcionariosAd);
  pageSize = 10;
  pageSizeOptions = [this.pageSize];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  mensagemNenhumRegistro = Mensagem.nenhumRegistroEncontrado;
  encontradoRegistro = true;

  desativarUsuarioSics: boolean = false;

  formulario = new FormGroup({
    nmFuncionario: new FormControl(),
    nuMatricula: new FormControl(),
    nmLogin: new FormControl(),
    dsCargo: new FormControl(),
    terceirizado: new FormControl(),
    status: new FormControl(),
    toggleStAtivo: new FormControl()
  });

  constructor(private formBuilder: FormBuilder,
    private usuarioAdService: UsuarioAdService,
    private messageService: MessageService,
    private router: Router,
    private usuarioService: UsuarioService,
    public dialog: MatDialog) { }

  ngOnInit() {
    
    this.formulario.controls.status.setValue('1');

    let usuario = this.usuarioService.obterUsuarioLogado();
    
    if (usuario)
    {      
      this.permissoesUsuario = usuario.claims;
      this.desativarUsuarioSics = this.verificarPermissao('desativar-usuarios-ad','visualizar');
    }
    
  }

  // ngAfterViewInit() {
  //   this.paginator.page.subscribe((event: any) => {
  //     this.atualizarFiltroPaginacao(event.pageIndex);
  //   });
  // }

  limparClick() {
    this.formulario.controls.nmFuncionario.setValue(null);
    this.formulario.controls.nuMatricula.setValue(null);
    this.formulario.controls.nmLogin.setValue(null);
    this.formulario.controls.dsCargo.setValue(null);
    this.formulario.controls.status.setValue('1');
    this.formulario.controls.terceirizado.setValue(false);
    this.funcionariosAd = new Array<Funcionario>();
    this.dataSource = new MatTableDataSource<Funcionario>(this.funcionariosAd);
    this.encontradoRegistro = true;
  }

  pesquisarClick() {
    this.submitted = true;

    if (this.formulario.valid) {
      //this.paginator.pageIndex = 0;
      this.pesquisar();
    }
  }

  validaCampos(filtro: Funcionario) {
    if (filtro.nmFuncionario?.length || filtro.nuMatricula > 0 || filtro.nmLogin?.length || filtro.dsCargo?.length) {
      return true;
    }

    this.messageService.error(Mensagem.preencherCamposConsultaUsuarioAd);
    return false;
  }

  pesquisar() {
    let filtro = this.obterFiltro();
    //this.manterFiltroPesquisa(filtros);

    if (this.validaCampos(filtro)) {

      this.usuarioAdService.consultar(filtro)
        .subscribe(
          response => {
            this.funcionariosAd = this.usuarioAdService.handleResponse(response) as Funcionario[];
            if (this.funcionariosAd) {
              this.encontradoRegistro = this.funcionariosAd.length > 0;
              this.dataSource = new MatTableDataSource<Funcionario>(this.funcionariosAd);
              this.dataSource.sort = this.sort;
              this.dataSource.paginator = this.paginator;
            }
            else {
              this.messageService.error(Mensagem.erroAoPesquisar);
            }
          }
        );
    }
  }

  obterFiltro() {
    let filtro = new Funcionario();

    filtro.nmFuncionario = this.formulario.controls.nmFuncionario.value?.trim() || undefined;
    filtro.nuMatricula = parseInt(this.formulario.controls.nuMatricula.value, 10) || undefined;
    filtro.nmLogin = this.formulario.controls.nmLogin.value?.trim() || undefined;
    filtro.dsCargo = this.formulario.controls.dsCargo.value?.trim() || undefined;
    filtro.stMembro = this.formulario.controls.terceirizado.value || undefined;
    filtro.stSICSAtivoAD = this.formulario.controls.status.value == "1"

    return filtro;
  }

  alternarTerceirizado() {
    if (this.formulario.controls.terceirizado.value == 0) {
      this.formulario.controls.dsCargo.enable();
      this.formulario.controls.nuMatricula.enable();
    }
    else {
      this.formulario.controls.dsCargo.disable();
      this.formulario.controls.dsCargo.setValue(null);

      this.formulario.controls.nuMatricula.disable();
      this.formulario.controls.nuMatricula.setValue(null);
    }
  }

  editar(funcionario: Funcionario) {
    if (funcionario.stSICSAtivoAD) {
      this.submeterEdicao(funcionario);
    } else {
      this.messageService.error(Mensagem.usuarioJaDesativado);
      this.formulario.controls.toggleStAtivo.setValue(false);
    }
  }

  submeterEdicao(funcionario: Funcionario) {
    window.localStorage.removeItem('funcionario');
    window.localStorage.setItem('funcionario', JSON.stringify(funcionario));
    this.router.navigate([Pages.DesativarUsuariosAd.desativarUsuariosAd]);
  };

  verificarPermissao(tipo: string, valor: string) {
    let index = this.permissoesUsuario.findIndex(x => x.tipo == tipo && x.valor == valor);
    return index >= 0;
  }
}
