export class OrgaoUnidade
{
    idOrgaoUnidade: number;
    descricao: string;
    nomeMunicipio: string;
    cdMunicipio: number;
    ativo: boolean;
    tipoOrgaoExterno: number;
    cnpj: string;
    dsOrgaoUnidade: string;
    dsOrgaoUnidadeCompleta: string;
    tipoOrgaoUnidadeId: number
}