import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[charFilter]'
})
export class CharFilterDirective {
  _actionKeys = [
    'ArrowUp',
    'ArrowRight',
    'ArrowDown',
    'ArrowLeft',
    'PageUp',
    'PageDown',
    'Home',
    'End',
    'Delete',
    'Backspace',
    'Tab',
    'Escape',
    'Enter',
    'Insert'
  ];
  _actionChars = 'aAcCvVxX';
  _regexFilter: RegExp;

  @Input('charFilter')
  set filter(value: string) {
    this._regexFilter = new RegExp(value);
  }

  constructor(private _el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this._actionKeys.indexOf(e.key) > -1 ||
      (e.ctrlKey === true && this._actionChars.indexOf(e.key) > -1) ||
      (e.metaKey === true && this._actionChars.indexOf(e.key) > -1)
    ) {
      return; // Accepted
    }

    if (!this._regexFilter.test(e.key)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent) {
    e.preventDefault();
    const text = e.clipboardData.getData('text/plain');
    if (this._regexFilter.test(text)) {
      document.execCommand('insertText', false, text);
    }
  }

  @HostListener('drop', ['$event'])
  onDrop(e: DragEvent) {
    e.preventDefault();
    const text = e.dataTransfer.getData('text');
    if (this._regexFilter.test(text)) {
      document.execCommand('insertText', false, text);
      this._el.nativeElement.focus();
    }
  }
}
