import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { Observable } from "rxjs";
import {
  startWith,
  distinctUntilChanged,
  tap,
  switchMap,
  finalize,
} from "rxjs/operators";
import { MessageService } from "src/app/services/message.service";
import { Sistema } from "src/app/services/models/constants";
import { Aplicacao } from "../models/aplicacao";
import { Grupo } from "../models/grupo";
import { GrupoSistema } from "../models/grupoSistema";
import { VincularGrupoSistemaService } from "./Services/vincular-grupo-sistema.service";
import { ConfirmComponent } from "src/app/shared/confirm/confirm.component";
import { Mensagem } from "src/app/shared/models/mensagem";
import { StringUtils } from "src/app/common/data-type-utils/string-utils";
import { Select2OptionData } from "ng-select2";
import { Options } from "select2";
import { toInteger } from "@ng-bootstrap/ng-bootstrap/util/util";
import { Usuario } from "../usuario/models/usuario";
import { UsuarioService } from "../usuario/services/usuario.service";

declare var $: any;

@Component({
  selector: "app-vincular-grupo-sistema",
  templateUrl: "./vincular-grupo-sistema.component.html",
  styleUrls: ["./vincular-grupo-sistema.component.scss"],
})
export class VincularGrupoSistemaComponent implements OnInit {
  public options: Options;

  gruposNaoAtribuidos: Array<Grupo> = new Array<Grupo>();
  gruposNaoAtribuidosAux: Array<Grupo> = new Array<Grupo>();
  gruposAtribuidos: Array<Grupo> = new Array<Grupo>();

  gruposNaoAtribuidosSelecionados: Array<Grupo> = new Array<Grupo>();
  gruposAtribuidosSelecionados: Array<Grupo> = new Array<Grupo>();

  displayedColumns = ["nmGrupo", "acoes"];
  dataSourceAtribuidos = new MatTableDataSource<Grupo>(this.gruposAtribuidos);
  dataSourceNaoAtribuidos = new MatTableDataSource<Grupo>(
    this.gruposNaoAtribuidos
  );
  pageSize = 10;
  pageSizeOptions = [this.pageSize];

  lengthAssociados = this.gruposAtribuidos.length;
  lengthDisponivel = this.gruposNaoAtribuidos.length;

  @ViewChild("paginator") paginator: MatPaginator;
  @ViewChild("paginatorDisponivel") paginatorDisponivel: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  sistemaSelecionado: Aplicacao = new Aplicacao();
  sistemaCarregando: boolean = false;
  sistemasObservable: Observable<any[]>;
  gruposObservable: Observable<any[]>;

  sistemas: Aplicacao[] = new Array<Aplicacao>();

  consultarDadosForm = new FormGroup({
    nmSistema: new FormControl(),
    nmGrupo: new FormControl(),
    selectSistema: new FormControl(),
  });

  descricaoSistema: string = "";

  btnSalvar: boolean = true;
  btnVincular: boolean = true;

  msg03: string = "Teste mensagem not found!";

  private _value: string;
  private usuarioLogado: Usuario;

  constructor(
    private vincularGrupoSistemaService: VincularGrupoSistemaService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private usuarioService: UsuarioService
  ) {}

  ngOnInit() {
    this.usuarioLogado = this.usuarioService.obterUsuarioLogado();
    this.sistemaInitAutocomplete();
    this.grupoInitAutoComplete();
    this.obterSistemas();
    this.sistemaSelectInitAutocomplete();

    let mensagem = Mensagem.MSG06;

    this.options = {
      theme: "classic",
      width: "500",
      language: {
        noResults: function () {
          return mensagem;
        },
      },
    };

    /*$("#selectSistema").select2({
      language: {
        noResults: (term) => {
          console.log("noResults", term);
          return '<b>No results matches!!!</b>';
        }
      }
    });*/
  }

  get value(): string {
    return this._value;
  }
  set value(value: string) {
    console.log(value);
    this.selecionarSistemaResponsavelId(value);
    this._value = value;
  }

  get controls() {
    return this.consultarDadosForm.controls;
  }

  salvar() {
    if (
      (this.gruposAtribuidos.length > 0 ||
        this.gruposNaoAtribuidos.length > 0) &&
      this.sistemaSelecionado.idAplicacao > 0
    ) {
      let grupoSistema = new GrupoSistema();
      grupoSistema.idAplicacao = this.sistemaSelecionado.idAplicacao;
      grupoSistema.grupo = this.gruposAtribuidos;

      this.vincularGrupoSistemaService
        .salvarGrupoSistema(grupoSistema)
        .subscribe((response) => {
          if (response) {
            this.messageService.success("Grupos associados com sucesso !");
            this.btnSalvar = true;
          } else {
            this.messageService.error(response.errors[0]);
          }
        });
    }
  }

  visualizarSistemaSelecionado(aplicacao: Aplicacao) {
    if (aplicacao && aplicacao.idAplicacao != 0)
      return aplicacao.sgAplicacao + " - " + aplicacao.nmAplicacao;
    else return "";
  }

  selecionarSistemaResponsavel(aplicacao: Aplicacao) {
    this.descricaoSistema = "";
    console.log("Sistema selecionado => ", aplicacao);
    this.descricaoSistema = aplicacao.dsAplicacao;
    this.sistemaSelecionado = aplicacao;

    if (this.sistemaSelecionado.idAplicacao > 0) {
      this.gruposAtribuidos = new Array<Grupo>();
      this.gruposNaoAtribuidos = new Array<Grupo>();

      this.obterGruposAtribuidos(this.sistemaSelecionado.idAplicacao);
      this.obterGruposDisponiveis(this.sistemaSelecionado.idAplicacao);
      
      let dsgrupo = this.controls.nmGrupo.value;
      if (dsgrupo) this.controls.nmGrupo.setValue(dsgrupo, { emitEvent: true });
    } else {
      this.gruposAtribuidos = new Array<Grupo>();
      this.gruposNaoAtribuidos = new Array<Grupo>();

      this.carregarGridGpAtribuidos();
      this.carregarGridGpNaoAtribuidos();
    }
  }

  selecionarSistemaResponsavelId(idAplicacao: string) {
    this.descricaoSistema = "";
    var aplicacao = this.sistemas.find(
      (x) => x.idAplicacao == parseInt(idAplicacao)
    );

    if (aplicacao != undefined && aplicacao.idAplicacao > 0) {
      this.gruposAtribuidos = new Array<Grupo>();
      this.gruposNaoAtribuidos = new Array<Grupo>();

      console.log("Sistema selecionado => ", aplicacao);

      this.descricaoSistema = aplicacao.dsAplicacao;
      this.sistemaSelecionado = aplicacao;
      this.obterGruposAtribuidos(this.sistemaSelecionado.idAplicacao);
      this.obterGruposDisponiveis(this.sistemaSelecionado.idAplicacao);
      let dsgrupo = this.controls.nmGrupo.value;
      if 
        (dsgrupo) this.controls.nmGrupo.setValue(dsgrupo, { emitEvent: true });
    } else {
      this.gruposAtribuidos = new Array<Grupo>();
      this.gruposNaoAtribuidos = new Array<Grupo>();

      this.carregarGridGpAtribuidos();
      this.carregarGridGpNaoAtribuidos();
    }
  }

  sistemaInitAutocomplete() {
    this.sistemasObservable = this.controls.nmSistema.valueChanges.pipe(
      startWith(null),
      distinctUntilChanged(),
      tap(() => {
        this.sistemaCarregando = true;
      }),
      switchMap((value) => {
        return this.vincularGrupoSistemaService
          .obterAplicacaoPorNome(value || "")
          .pipe(
            finalize(() => {
              this.sistemaCarregando = false;
            })
          );
      })
    );
  }

  sistemaSelectInitAutocomplete() {
    //this.controls.selectSistema.updateOn
    //.subscribe(response => {if(response) this.selecionarSistemaResponsavelId(response);} );
  }

  obterSistemas() {
    this.vincularGrupoSistemaService.obterAplicacao().subscribe((response) => {
      if (response)
        this.sistemas = this.vincularGrupoSistemaService.handleResponse(
          response
        ) as Aplicacao[];

      console.log("sistema => ", this.sistemas);
    });
  }

  grupoInitAutoComplete() {
    this.controls.nmGrupo.valueChanges
      .pipe(
        tap(() => {
          this.sistemaCarregando = true;
        })
      )
      .subscribe((value) => {
        /*this.gruposAssociados = this.gruposDisponiveisAux;
      this.gruposAssociados = this.gruposAssociados.filter(grupo => { return grupo.nmGrupo.toLowerCase().includes(value) || grupo.sgGrupo.toLowerCase().includes(value) } );
      if(this.gruposAssociados.length >0)
        this.carregarGridGpAssociados();*/
        this.vincularGrupoSistemaService
          .obterGrupoPorNome(value || "", this.sistemaSelecionado.idAplicacao)
          .pipe(
            finalize(() => {
              this.sistemaCarregando = false;
            })
          )
          .subscribe((response) => {
            if (response != undefined && response.length > 0) {
              console.log("Response => ", response);
              this.gruposNaoAtribuidos = response;
              let indexNaoAtribuidos: Grupo[] = new Array<Grupo>();
              this.gruposNaoAtribuidos.forEach((grupo) => {
                if (
                  this.gruposAtribuidos.findIndex((gpdisp) => {
                    return gpdisp.idGrupo == grupo.idGrupo;
                  }) > -1
                ) {
                  let gp = this.gruposNaoAtribuidos.find((gpdisp) => {
                    return gpdisp.idGrupo == grupo.idGrupo;
                  });
                  indexNaoAtribuidos.push(gp);
                }
              });

              indexNaoAtribuidos.forEach((grupo) => {
                let index = this.gruposNaoAtribuidos.findIndex((gpdisp) => {
                  return gpdisp.idGrupo == grupo.idGrupo;
                });
                this.gruposNaoAtribuidos.splice(index, 1);
              });

              console.log("Index não atribuidos => ", indexNaoAtribuidos);
              console.log("Gp Atribuidos => ", this.gruposAtribuidos);
              console.log("Gp Não Atribuidos => ", this.gruposNaoAtribuidos);
              this.carregarGridGpNaoAtribuidos();
            } else {
              if (
                !StringUtils.isNullOrEmpty(value) &&
                value.trim().length >= 3
              ) {
                this.gruposNaoAtribuidos = new Array<Grupo>();
                this.carregarGridGpNaoAtribuidos();
                this.messageService.warning(Mensagem.MSG08);
              }
            }
          });
      });
  }

  obterGruposDisponiveis(idAplicacao: number) {
    
        this.vincularGrupoSistemaService
          .obterGruposDisponiveis(idAplicacao)
          .pipe(
            finalize(() => {
              this.sistemaCarregando = false;
            })
          )
          .subscribe((response) => {
            if (response != undefined) {
              console.log("Response => ", response);
              this.gruposNaoAtribuidos = this.vincularGrupoSistemaService.handleResponse(
                response
              ) as Grupo[];
              let indexNaoAtribuidos: Grupo[] = new Array<Grupo>();
              this.gruposNaoAtribuidos.forEach((grupo) => {
                if (
                  this.gruposAtribuidos.findIndex((gpdisp) => {
                    return gpdisp.idGrupo == grupo.idGrupo;
                  }) > -1
                ) {
                  let gp = this.gruposNaoAtribuidos.find((gpdisp) => {
                    return gpdisp.idGrupo == grupo.idGrupo;
                  });
                  indexNaoAtribuidos.push(gp);
                }
              });

              indexNaoAtribuidos.forEach((grupo) => {
                let index = this.gruposNaoAtribuidos.findIndex((gpdisp) => {
                  return gpdisp.idGrupo == grupo.idGrupo;
                });
                this.gruposNaoAtribuidos.splice(index, 1);
              });

              console.log("Index não atribuidos => ", indexNaoAtribuidos);
              console.log("Gp Atribuidos => ", this.gruposAtribuidos);
              console.log("Gp Não Atribuidos => ", this.gruposNaoAtribuidos);
              this.carregarGridGpNaoAtribuidos();
            } 
          });
  }

  carregarGridGpNaoAtribuidos() {
    this.dataSourceNaoAtribuidos = new MatTableDataSource<Grupo>(
      this.gruposNaoAtribuidos
    );
    this.dataSourceNaoAtribuidos.paginator = this.paginatorDisponivel;

    this.dataSourceNaoAtribuidos.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "nmGrupo":
          return item.nmGrupo.toUpperCase();
        default:
          return item[property];
      }
    };

    this.dataSourceNaoAtribuidos.sort = this.sort;
  }

  private obterGruposAtribuidos(idAplicacao: number) {
    this.vincularGrupoSistemaService
      .obterGrupoAtribuidos(idAplicacao)
      .subscribe((Response) => {
        this.gruposAtribuidos = this.vincularGrupoSistemaService.handleResponse(
          Response
        ) as Grupo[];
        this.gruposNaoAtribuidosAux = this.gruposAtribuidos;
        this.carregarGridGpAtribuidos();
      });
  }

  carregarGridGpAtribuidos() {
    this.dataSourceAtribuidos = new MatTableDataSource<Grupo>(
      this.gruposAtribuidos
    );
    this.dataSourceAtribuidos.paginator = this.paginator;

    this.dataSourceAtribuidos.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "nmGrupo":
          return item.nmGrupo.toUpperCase();
        default:
          return item[property];
      }
    };

    this.dataSourceAtribuidos.sort = this.sort;
  }

  selecionarDisponivel(elemento: Grupo) {
    if (elemento.idGrupo != undefined && elemento.idGrupo > 0) {
      if (
        !this.gruposNaoAtribuidosSelecionados.find(
          (grupo) => grupo.idGrupo == elemento.idGrupo
        )
      ) {
        this.gruposNaoAtribuidosSelecionados.push(elemento);
      } else {
        let index = this.gruposNaoAtribuidosSelecionados.findIndex(
          (grupo) => grupo.idGrupo == elemento.idGrupo
        );

        this.gruposNaoAtribuidosSelecionados.splice(index, 1);
      }

      if (
        this.gruposAtribuidosSelecionados.length > 0 ||
        this.gruposNaoAtribuidosSelecionados.length > 0
      )
        this.btnVincular = false;
      else this.btnVincular = true;

      console.log(
        "grupos disp selecionados => ",
        this.gruposNaoAtribuidosSelecionados
      );
    }
  }

  selecionarAtribuidos(elemento: Grupo) {
    if (elemento.idGrupo != undefined && elemento.idGrupo > 0) {
      if (
        !this.gruposAtribuidosSelecionados.find(
          (grupo) => grupo.idGrupo == elemento.idGrupo
        )
      ) {
        this.gruposAtribuidosSelecionados.push(elemento);
      } else {
        let index = this.gruposAtribuidosSelecionados.findIndex(
          (grupo) => grupo.idGrupo == elemento.idGrupo
        );

        this.gruposAtribuidosSelecionados.splice(index, 1);
      }

      if (
        this.gruposAtribuidosSelecionados.length > 0 ||
        this.gruposNaoAtribuidosSelecionados.length > 0
      )
        this.btnVincular = false;
      else this.btnVincular = true;

      console.log(
        "grupos atribuidos selecionados => ",
        this.gruposAtribuidosSelecionados
      );
    }
  }

  incluirSelecionados() {
    if (this.gruposNaoAtribuidosSelecionados.length > 0) {
      this.gruposNaoAtribuidosSelecionados.forEach((grupos) => {
        grupos.selecionado = false;
      }, this.gruposNaoAtribuidosSelecionados);

      this.gruposAtribuidos = this.gruposNaoAtribuidosAux;
      this.gruposAtribuidos.unshift(...this.gruposNaoAtribuidosSelecionados);
      this.carregarGridGpAtribuidos();

      this.gruposNaoAtribuidosSelecionados.forEach((grupo) => {
        let index = this.gruposNaoAtribuidos.findIndex(
          (gpdisp) => gpdisp.idGrupo == grupo.idGrupo
        );
        this.gruposNaoAtribuidos.splice(index, 1);
      });
      this.carregarGridGpNaoAtribuidos();
      console.log(
        "gruposAssociados disp selecionados => ",
        this.gruposAtribuidos
      );

      this.gruposNaoAtribuidosSelecionados = [];
      this.btnSalvar = false;
    }
  }

  excluirSelecionados() {
    if (this.gruposAtribuidosSelecionados.length > 0) {
      let mensagem = Mensagem.MSE02.replace(
        "{Sistema}",
        this.sistemaSelecionado.sgAplicacao +
          " - " +
          this.sistemaSelecionado.nmAplicacao
      );

      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: "450px",
        height: "220px",
        data: { message: mensagem },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.gruposAtribuidosSelecionados.forEach((grupos) => {
            grupos.selecionado = false;
          }, this.gruposAtribuidosSelecionados);

          //this.gruposAssociados = this.gruposDisponiveisAux;
          this.gruposNaoAtribuidos.unshift(
            ...this.gruposAtribuidosSelecionados
          );
          this.carregarGridGpNaoAtribuidos();

          this.gruposAtribuidosSelecionados.forEach((grupo) => {
            let index = this.gruposAtribuidos.findIndex(
              (gpdisp) => gpdisp.idGrupo == grupo.idGrupo
            );
            this.gruposAtribuidos.splice(index, 1);
          });
          this.carregarGridGpAtribuidos();
          console.log(
            "gruposDisponiveis disp selecionados => ",
            this.gruposNaoAtribuidos
          );

          this.gruposAtribuidosSelecionados = [];
          this.btnSalvar = false;
        }
      });
    }
  }

  incluirTodos() {
    if (this.gruposNaoAtribuidos.length > 0) {
      this.gruposAtribuidos.unshift(...this.gruposNaoAtribuidos);
      this.gruposNaoAtribuidos = [];
      this.carregarGridGpNaoAtribuidos();
      this.carregarGridGpAtribuidos();
      this.btnSalvar = false;
    }
  }

  excluirTodos() {
    if (this.gruposAtribuidos.length > 0) {
      let mensagem = Mensagem.MSE02.replace(
        "{Sistema}",
        this.sistemaSelecionado.sgAplicacao +
          " - " +
          this.sistemaSelecionado.nmAplicacao
      );

      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: "450px",
        height: "220px",
        data: { message: mensagem },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.gruposNaoAtribuidos.unshift(...this.gruposAtribuidos);
          this.gruposAtribuidos = [];
          this.carregarGridGpAtribuidos();
          this.carregarGridGpNaoAtribuidos();
          this.btnSalvar = false;
        }
      });
    }
  }
}
