// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  padding: 1rem;
  color: #495057;
  background-color: white;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/rodape/rodape.component.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,cCOW;EDNX,uBAAA;EACA,kBAAA;AADF","sourcesContent":["@import \"/src/assets/styles/colors\";\r\n\r\nfooter {\r\n  padding: 1rem;\r\n  color: $cinza-text;\r\n  background-color: white;\r\n  text-align: center;\r\n}\r\n","$azul-base: #046fae;\r\n$azul-ativo: #065c8d;\r\n$azul-hover-grid: #dcf1fa;\r\n$azul-icone:#0086bf;\r\n$azul-botao: #337ab7;\r\n$azul-header-table:#47b6e4;\r\n$branco: #ffffff;\r\n$preto: #000000;\r\n$cinza-base: #B7B6BB;\r\n$cinza-claro: #ededed;\r\n$cinza-hover: #d8d8d8;\r\n$cinza-text: #495057;\r\n$cinza-border: #ced4da;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
