export class UsuarioDesativacaoFiltro {
    nome: string;
    login: string;
    matricula: number;
    cargo: string;
    nomeUsuarioDesativacao: string;
    dataDesativacaoInicial: Date;
    dataDesativacaoFinal: Date;
    numeroDje: string;
    dataPublicacaoInicial: Date;
    dataPublicacaoFinal: Date;
}