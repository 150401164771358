import { BrowserModule } from "@angular/platform-browser";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxCaptchaModule } from "ngx-captcha";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { ToastPosition } from "./shared/models/toastr-position";
import { Idle } from "@ng-idle/core";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { SharedModule } from "./shared/shared.module";
import { AngularDualListBoxModule } from "angular-dual-listbox";
import { RecaptchaModule } from "ng-recaptcha";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";

// Services
import { RequestInterceptor } from "./services/request.interceptor.service";
import { UsuarioService } from "./SICSv2/usuario/services/usuario.service";
import { MessageService } from "./services/message.service";
import { BaseService } from "./services/base.service";
import { AuthService } from "./services/auth.service";
import { IdleService } from "./services/idle.service";
import { UsuarioDesativacaoService } from "./SICSv2/relatorio-usuarios-ad/Services/usuario-desativacao.service";
import { UsuarioAdService } from "./SICSv2/desativar-usuarios-ad/Services/usuario-ad.service";

// components
import { AppComponent } from "./app.component";
import { HomeComponent } from "./SICSv2/home/home.component";
import { LoginComponent } from "./SICSv2/usuario/login/login.component";
import { EsqueciSenhaComponent } from "./SICSv2/usuario/esqueci-senha/esqueci-senha.component";
import { AlterarSenhaComponent } from "./SICSv2/usuario/alterar-senha/alterar-senha.component";
import { TopoLoginComponent } from "./SICSv2/usuario/topo-login/topo-login.component";
import { CentroCustoComponent } from "./SICSv2/usuario/centro-custo/centro-custo.component";
import { ConsultarUsuariosAdComponent } from "./SICSv2/consultar-usuarios-ad/consultar-usuarios-ad.component";
import { DesativarUsuariosAdComponent } from "./SICSv2/desativar-usuarios-ad/desativar-usuarios-ad.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RelatorioUsuariosAdComponent } from "./SICSv2/relatorio-usuarios-ad/relatorio-usuarios-ad.component";
import { VincularGrupoSistemaComponent } from "./SICSv2/vincular-grupo-sistema/vincular-grupo-sistema.component";

// directives
import { CharFilterDirective } from "./directives/char-filter.directive";
import { DateRangeCheckDirective } from "./directives/date-range-check.directive";
import { BrazilianDateAdapter } from "./common/adapters/BrazilianDateAdapter";
import { VincularGrupoSistemaService } from "./SICSv2/vincular-grupo-sistema/Services/vincular-grupo-sistema.service";
import { VincularUsuarioGestorSistemaComponent } from "./SICSv2/vincular-usuario-gestor-sistema/vincular-usuario-gestor-sistema.component";
import { VincularUsuarioGestorSistemaService } from "./SICSv2/vincular-usuario-gestor-sistema/Services/vincular-usuario-gestor-sistema.service";
import { VincularOrgaoUnidadeUsuarioComponent } from "./SICSv2/vincular-orgao-unidade-usuario/vincular-orgao-unidade-usuario.component";
import { AtribuirPermissaoPorAplicacaoComponent } from "./SICSv2/atribuir-permissao-por-aplicacao/atribuir-permissao-por-aplicacao.component";
import { VincularOrgaoUnidadeModalComponent } from "./shared/vincular-orgao-unidade-modal/vincular-orgao-unidade-modal.component";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EsqueciSenhaComponent,
    AlterarSenhaComponent,
    TopoLoginComponent,
    HomeComponent,
    CentroCustoComponent,
    ConsultarUsuariosAdComponent,
    DesativarUsuariosAdComponent,
    RelatorioUsuariosAdComponent,
    VincularGrupoSistemaComponent,
    VincularUsuarioGestorSistemaComponent,
    VincularOrgaoUnidadeUsuarioComponent,
    AtribuirPermissaoPorAplicacaoComponent,
    VincularOrgaoUnidadeModalComponent,
    CharFilterDirective,
    DateRangeCheckDirective,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    HttpClientModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      positionClass: ToastPosition.bottomRight,
    }),
    AngularDualListBoxModule,
    RecaptchaModule,
    MatSlideToggleModule,
  ],
  exports: [BrowserModule, BrowserAnimationsModule],
  providers: [
    [
      {
        provide: MAT_DATE_LOCALE,
        useValue: "pt-br",
      },
      {
        provide: DateAdapter,
        useClass: BrazilianDateAdapter,
      },
    ],
    AuthService,
    BaseService,
    MessageService,
    UsuarioService,
    UsuarioDesativacaoService,
    UsuarioAdService,
    NgbModal,
    VincularGrupoSistemaService,
    VincularUsuarioGestorSistemaService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    Idle,
    IdleService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
