// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-dialog-container {
  padding: 6px !important;
}

::ng-deep .mat-dialog-content {
  padding: 4px !important;
  margin: 0 !important;
  padding-top: 25px !important;
}

.altura-confirm {
  height: 156px !important;
  text-align: center !important;
}

.title-confirm {
  background-color: #046fae;
  color: #ffffff;
  padding: 5px;
  font-size: 14px;
  text-align: center;
}

.content-confirm {
  font-size: 12px;
}

p {
  margin-bottom: 0 !important;
}

@media (min-width: 576px) {
  p {
    margin-bottom: 1.2em !important;
  }
  ::ng-deep .mat-dialog-content {
    padding-top: 28px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/confirm/confirm.component.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,uBAAA;AADF;;AAIA;EACE,uBAAA;EACA,oBAAA;EACA,4BAAA;AADF;;AAIA;EACE,wBAAA;EACA,6BAAA;AADF;;AAIA;EACE,yBClBU;EDmBV,cCbO;EDcP,YAAA;EACA,eAAA;EACA,kBAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,2BAAA;AADF;;AAIA;EACE;IACE,+BAAA;EADF;EAIA;IACE,4BAAA;EAFF;AACF","sourcesContent":["@import \"/src/assets/styles/colors\";\r\n\r\n::ng-deep .mat-dialog-container {\r\n  padding: 6px !important;\r\n}\r\n\r\n::ng-deep .mat-dialog-content {\r\n  padding: 4px !important;\r\n  margin: 0 !important;\r\n  padding-top: 25px !important;\r\n}\r\n\r\n.altura-confirm {\r\n  height: 156px !important;\r\n  text-align: center !important;\r\n}\r\n\r\n.title-confirm {\r\n  background-color: $azul-base;\r\n  color: $branco;\r\n  padding: 5px;\r\n  font-size: 14px;\r\n  text-align: center;\r\n}\r\n\r\n.content-confirm {\r\n  font-size: 12px;\r\n}\r\n\r\np {\r\n  margin-bottom: 0 !important;\r\n}\r\n\r\n@media (min-width: 576px) {\r\n  p {\r\n    margin-bottom: 1.2em !important;\r\n  }\r\n\r\n  ::ng-deep .mat-dialog-content {\r\n    padding-top: 28px !important;\r\n  }\r\n}\r\n","$azul-base: #046fae;\r\n$azul-ativo: #065c8d;\r\n$azul-hover-grid: #dcf1fa;\r\n$azul-icone:#0086bf;\r\n$azul-botao: #337ab7;\r\n$azul-header-table:#47b6e4;\r\n$branco: #ffffff;\r\n$preto: #000000;\r\n$cinza-base: #B7B6BB;\r\n$cinza-claro: #ededed;\r\n$cinza-hover: #d8d8d8;\r\n$cinza-text: #495057;\r\n$cinza-border: #ced4da;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
