import { Claim } from './claim';

export class Usuario {
    //colaboradorId: number;
    nome: string;
    login: string;
    usuarioSics: number;
    matriculaSics: string;
    //orgaoExternoId: number;
    nomeMunicipio: string;
    autenticado: boolean;
    //primeiroLogin: boolean;
    centroCustoId: number;
    centroCusto: string;
    token: string;
    claims: Array<Claim>;
    idUsuario: number;
    nuMatricula: number;
    nmLogin: string;
    nmUsuario: string;
}