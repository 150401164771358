export class Grupo {
    idGrupo: number;
    id: number;
    nmGrupo: string;
    sgGrupo: string;
    dsGrupo: string;
    selecionado: boolean;
    acao: string;
    idUsuario: number;
}
