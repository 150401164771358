// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  width: 100%;
  height: auto;
  /*border: 2px solid #14142B;*/
  border-radius: 0px;
  border-radius: 0px;
  /*overflow-y: scroll; */
}

.list-button {
  width: 40px;
  padding: 0px;
}

.unclick {
  pointer-events: none;
}

.div-list-item {
  vertical-align: middle;
  font-size: 1rem;
  padding: 4px 16px;
}

.div-list-item:hover {
  background-color: #dcf1fa !important;
  font-weight: normal;
  cursor: pointer;
}

.div-check {
  width: 46px;
  pointer-events: none;
  float: left;
}

.table-row {
  height: 30px !important;
}

table {
  width: 100% !important;
  border-collapse: collapse !important;
  border-radius: 5em !important;
}

.mat-table {
  background-color: transparent !important;
}

table tr:last-child td {
  border-bottom: 0 solid !important;
}

.select2-container {
  min-width: 500px;
  border-radius: 14px !important;
  border-radius: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/SICSv2/vincular-grupo-sistema/vincular-grupo-sistema.component.scss"],"names":[],"mappings":"AACA;EACE,WAAA;EACA,YAAA;EACA,6BAAA;EACA,kBAAA;EAGA,kBAAA;EACA,uBAAA;AAAF;;AAGA;EACE,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,oBAAA;AAAF;;AAGA;EACE,sBAAA;EACA,eAAA;EACA,iBAAA;AAAF;;AAGA;EACE,oCAAA;EACA,mBAAA;EACA,eAAA;AAAF;;AAGA;EACE,WAAA;EACA,oBAAA;EACA,WAAA;AAAF;;AAGA;EACE,uBAAA;AAAF;;AAGA;EACE,sBAAA;EACA,oCAAA;EACA,6BAAA;AAAF;;AAGA;EACE,wCAAA;AAAF;;AAGA;EACE,iCAAA;AAAF;;AAGA;EACE,gBAAA;EACA,8BAAA;EAGA,8BAAA;AAAF","sourcesContent":["@import \"/src/assets/styles/colors\";\r\n.list {\r\n  width: 100%;\r\n  height: auto;\r\n  /*border: 2px solid #14142B;*/\r\n  border-radius: 0px;\r\n  -moz-border-radius: 0px;\r\n  -webkit-border-radius: 0px;\r\n  border-radius: 0px;\r\n  /*overflow-y: scroll; */\r\n}\r\n\r\n.list-button {\r\n  width: 40px;\r\n  padding: 0px;\r\n}\r\n\r\n.unclick {\r\n  pointer-events: none;\r\n}\r\n\r\n.div-list-item {\r\n  vertical-align: middle;\r\n  font-size: 1rem;\r\n  padding: 4px 16px;\r\n}\r\n\r\n.div-list-item:hover {\r\n  background-color: $azul-hover-grid !important;\r\n  font-weight: normal;\r\n  cursor: pointer;\r\n}\r\n\r\n.div-check {\r\n  width: 46px;\r\n  pointer-events: none;\r\n  float: left;\r\n}\r\n\r\n.table-row {\r\n  height: 30px !important;\r\n}\r\n\r\ntable {\r\n  width: 100% !important;\r\n  border-collapse: collapse !important;\r\n  border-radius: 5em !important;\r\n}\r\n\r\n.mat-table {\r\n  background-color: transparent !important;\r\n}\r\n\r\ntable tr:last-child td /*To remove the last border*/ {\r\n  border-bottom: 0 solid !important;\r\n}\r\n\r\n.select2-container {\r\n  min-width: 500px;\r\n  border-radius: 14px !important;\r\n  -moz-border-radius: 14px !important;\r\n  -webkit-border-radius: 14px !important;\r\n  border-radius: 14px !important;\r\n}\r\n\r\n.js-example-basic-single {\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
