import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Mensagem } from '../shared/models/mensagem';
import { MessageService } from './message.service';
import { environment } from "../../environments/environment";

@Injectable()
export class BaseService {

    private _apiUrl: string;

    constructor(protected message: MessageService, protected http: HttpClient) { }

    get apiUrl(): string {
        
        if (this._apiUrl == null) {
            this._apiUrl = environment.api;

            if (!this._apiUrl) {
                throw Error("Url da API não configurada.");
            }
        }

        return this._apiUrl;
    }

    handleError(error: any, showMessage: boolean = true) {
        if (showMessage)
            this.message.error(Mensagem.erroGenerico);
    }

    handleResponse(response: any) {
        if (response.success && this.hasInformations(response))
            this.message.success(response.informations[0]);
        else if (this.hasErrors(response))
            this.message.error(response.errors[0] == "Usuário ou Senha inválido." ? Mensagem.loginInvalido : response.errors[0]);

        return response.data || {};
    }

    hasInformations(response: any): boolean {
        let messages = response.informations as Array<string>
        return messages != null && messages.length > 0;
    }

    hasErrors(response: any): boolean {
        let messages = response.errors as Array<string>
        return messages != null && messages.length > 0;
    }
}
