// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-header {
  background-color: #b4d6e5;
}

.line-header {
  background-color: #046fae;
  margin-top: 5px;
  padding-top: 5px;
}

.img {
  max-height: 96px;
}

@media (max-width: 767px) {
  .img {
    width: 100%;
    max-height: 96px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/SICSv2/usuario/topo-login/topo-login.component.scss","webpack://./src/assets/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,yBAAA;AADF;;AAIA;EACE,yBCPU;EDQV,eAAA;EACA,gBAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE;IACE,WAAA;IACA,gBAAA;EADF;AACF","sourcesContent":["@import \"/src/assets/styles/colors\";\r\n\r\n.page-header {\r\n  background-color: #b4d6e5;\r\n}\r\n\r\n.line-header {\r\n  background-color: $azul-base;\r\n  margin-top: 5px;\r\n  padding-top: 5px;\r\n}\r\n\r\n.img {\r\n  max-height: 96px;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n  .img {\r\n    width: 100%;\r\n    max-height: 96px;\r\n  }\r\n}\r\n","$azul-base: #046fae;\r\n$azul-ativo: #065c8d;\r\n$azul-hover-grid: #dcf1fa;\r\n$azul-icone:#0086bf;\r\n$azul-botao: #337ab7;\r\n$azul-header-table:#47b6e4;\r\n$branco: #ffffff;\r\n$preto: #000000;\r\n$cinza-base: #B7B6BB;\r\n$cinza-claro: #ededed;\r\n$cinza-hover: #d8d8d8;\r\n$cinza-text: #495057;\r\n$cinza-border: #ced4da;\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
