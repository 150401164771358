import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { StringUtils } from "src/app/common/data-type-utils/string-utils";
import { BaseService } from "src/app/services/base.service";
import { MessageService } from "src/app/services/message.service";
import { Mensagem } from "src/app/shared/models/mensagem";
import { OrgaoUnidadeApi } from "../../models/orgao-unidade-api";
import { OrgaoUnidade } from "../../models/orgaoUnidade";
import { Response } from "src/app/shared/models/response";

@Injectable({
  providedIn: "root",
})
export class OrgaoUnidadeService extends BaseService {
  constructor(
    protected override message: MessageService,
    protected override http: HttpClient
  ) {
    super(message, http);
  }

  obterEntidadePorDescricao(descricao: string): Observable<OrgaoUnidade[]> {
    if (
      typeof descricao !== "string" ||
      StringUtils.isNullOrEmpty(descricao) ||
      descricao.trim().length < 3
    )
      return of(null);

    let orgaos = this.http
      .get<Response>(
        this.apiUrl + OrgaoUnidadeApi.obterEntidadePorDescricao + descricao
      )
      .pipe(
        debounceTime(300),
        map((response: Response) => {
          let data = response.data as OrgaoUnidade[];
          if (data != null && data.length != 0) return data;
          else {
            let vazio = new OrgaoUnidade();
            vazio.idOrgaoUnidade = 0;
            vazio.descricao = Mensagem.MSG10;
            return [vazio];
          }
        })
      );

    return orgaos;
  }
}
