import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { StringUtils } from '../common/data-type-utils/string-utils';
import { Sistema } from './models/constants';
import { ToastrService } from 'ngx-toastr';
import { Mensagem } from '../shared/models/mensagem';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pages } from '../shared/models/pages';
import { SpinnerIgnore } from './models/spinner-ignore';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(private router: Router, private toastr: ToastrService, private spinner: NgxSpinnerService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!SpinnerIgnore.ignoreList.some(x => request.url.includes(x.url)))
            this.spinner.show(undefined,
                {
                    type: 'ball-scale-ripple',
                    size: 'medium',
                    bdColor: 'rgba(0,0,0,0.70)',
                    color: 'white',
                    fullScreen: true
                }
            );

        let token = JSON.parse(localStorage.getItem(Sistema.localStorage.token));

        if (!StringUtils.isNullOrEmpty(token)) {
            request = request.clone({
                setHeaders: {
                    ContentType: 'application/json',
                    Authorization: `Bearer ${token}`
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    ContentType: 'application/json'
                }
            });
        }

        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401) {
                    localStorage.clear();
                    sessionStorage.clear();
                    this.router.navigate([Pages.Usuario.login]);
                }
                else if (error.status === 403) {
                    this.toastr.error(Mensagem.usuarioSemPermissao);
                }
                else if (error.url.includes('api/usuario/autenticar')) {
                    this.toastr.error(Mensagem.naoAcessaAD);
                }
                else if (error.message !== '') {
                    this.toastr.error(error.message);
                }
                console.error(error);
                return throwError(error);
            }),
            finalize(() => { this.spinner.hide() })
        );
    }
}