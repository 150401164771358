import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'descricaoMax'
})
export class DescricaoMaxPipe implements PipeTransform {

    transform(value: string, length: number = 135): string {
        if (value === null || value === undefined || value === '')
            return '';

        if (value.length <= length)
            return value;

        return value.substr(0, length) + '...';
    }

}

