import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[dateRangeCheck]'
})
export class DateRangeCheckDirective {
  
  constructor(private _el: ElementRef) {}

  @HostListener('blur', ['$event'])
  onBlur(e: UIEvent) {
    var value = this._el.nativeElement.value;
    
    var date = new Date(value);
    if (this.isValidDate(date)) {
      var min = this._el.nativeElement.getAttribute('min');
      var minDate = new Date(min);
      if (this.isValidDate(minDate)) {
        if (date < minDate) {
          this._el.nativeElement.value = minDate.toISOString().substr(0, 10);
        }
      }
  
      var max = this._el.nativeElement.getAttribute('max');
      var maxDate = new Date(max);
      if (this.isValidDate(maxDate)) {
        if (date > maxDate) {
          this._el.nativeElement.value = maxDate.toISOString().substr(0, 10);
        }
      }
    }
  }

  isValidDate (date: Date) {
    return date instanceof Date && !isNaN(date.valueOf())
  }
}
