// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-mdc-form-field-underline {
  display: none !important;
}

::ng-deep .mat-date-range-input-inner {
  font-size: 16px !important;
  font-family: "Poppins" !important;
}

::ng-deep .mat-date-range-input-mirror {
  font-size: 16px !important;
  font-family: "Poppins" !important;
}

::ng-deep .mat-mdc-form-field-infix {
  border-top: 0.09375em solid transparent !important;
}

::ng-deep .mat-mdc-form-field {
  font-size: initial;
}`, "",{"version":3,"sources":["webpack://./src/app/SICSv2/relatorio-usuarios-ad/relatorio-usuarios-ad.component.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;;AAEA;EACE,0BAAA;EACA,iCAAA;AACF;;AAEA;EACE,0BAAA;EACA,iCAAA;AACF;;AAEA;EACE,kDAAA;AACF;;AAEA;EACE,kBAAA;AACF","sourcesContent":["::ng-deep .mat-mdc-form-field-underline {\r\n  display: none !important;\r\n}\r\n\r\n::ng-deep .mat-date-range-input-inner {\r\n  font-size: 16px !important;\r\n  font-family: \"Poppins\" !important;\r\n}\r\n\r\n::ng-deep .mat-date-range-input-mirror {\r\n  font-size: 16px !important;\r\n  font-family: \"Poppins\" !important;\r\n}\r\n\r\n::ng-deep .mat-mdc-form-field-infix {\r\n  border-top: 0.09375em solid transparent !important;\r\n}\r\n\r\n::ng-deep .mat-mdc-form-field {\r\n  font-size: initial;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
