import { CustomValidators } from '../../../common/show-erros/custom-validators';
import { Mensagem } from '../../../shared/models/mensagem';
import { MessageService } from 'src/app/services/message.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from '../services/usuario.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { Router } from '@angular/router';
import { Pages } from 'src/app/shared/models/pages';
import { IdleService } from 'src/app/services/idle.service';
import { StringValidator } from 'src/app/common/validation/string-validator';
import { Usuario } from '../models/usuario';
import { Sistema } from 'src/app/services/models/constants';

@Component({
  selector: 'app-alterar-senha',
  templateUrl: './alterar-senha.component.html',
  styleUrls: ['./alterar-senha.component.css']
})
export class AlterarSenhaComponent implements OnInit {

  alterarsenhaForm: FormGroup;
  usuario: Usuario;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private idleService: IdleService,
    private usuarioService: UsuarioService,
    private messageService: MessageService
    ) { }

  ngOnInit() {
    this.alterarsenhaForm = this.carregarFormularioUsuario();
  }

  get controls() { return this.alterarsenhaForm.controls; }

  carregarFormularioUsuario(){
    return this.formBuilder.group({
        login: [ '', [Validators.required]],
        senhaAtual: ['', [Validators.required, StringValidator.minLength(1)]],
        novaSenha: ['', [Validators.required, StringValidator.minLength(6)]],
        confirmacao: ['', [Validators.required, StringValidator.minLength(1)]]
      }, {
        validator: MustMatch('novaSenha', 'confirmacao')
      });
  }

  loginFocus() {
    let value = this.controls.login.value as string;

    if (value.length == 14) {
      let cpf = value.replace('.', '').replace('.', '').replace('-', '');

      if (!Number.isNaN(Number(cpf)) && cpf.length == 11)
        this.controls.login.setValue(cpf);
    }
  }

  loginBlur() {
    let value = this.controls.login.value as string;

    if (value.length == 11 && !Number.isNaN(Number(value))) {
      let cpf = `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`;
      this.controls.login.setValue(cpf);
    }
  }

  alterarSenhaClick(){
    // if (!this.alterarsenhaForm.value)
    // return;
    // debugger;
    // this.usuarioService.alterarSenha(this.usuarioSistema.login, this.senhaAtualField.value, this.novaSenhaField.value)
    //   .subscribe(response => {
    //     console.log(this.usuarioSistema.login, this.senhaAtualField.value, this.novaSenhaField.value)
    //     this.usuarioService.handleResponse(response);
    //     this.router.navigate([Pages.Usuario.login]);
    //   });
    this.submitted = true;

    if (this.alterarsenhaForm.valid) {
      this.usuarioService.alterarSenha( this.controls.login.value, this.controls.senhaAtual.value, this.controls.novaSenha.value)
        .subscribe(response => {
          this.usuarioService.handleResponse(response);
          if (response.success) {
            this.messageService.success(Mensagem.operacaoSucesso);
            this.alterarsenhaForm.reset();
            this.router.navigate([Pages.Usuario.login]);
          }
        });
    }
  }

}

export function MustMatch(controlName: string, matchingControlName: string) {
   return (formGroup: FormGroup) => {
     const control = formGroup.controls[controlName];
     const matchingControl = formGroup.controls[matchingControlName];

     if (matchingControl.errors && !matchingControl.errors.mustMatch) {
       return;
     }

     if (control.value !== matchingControl.value) {
       matchingControl.setErrors({ mustMatch: true });
     } else {
       matchingControl.setErrors(null);
     }
   }
 }

 //export class ValidaLetrasNumeros {
   export function validaSenha(senha: string): boolean {
   debugger;
        let numeros: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
        let letras: string[] = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
        let retornoSenha: boolean = true;
        for (let i: number = 0; i < senha.length; i++) {
            if (!(senha.charAt(i) in numeros) || !(senha.charAt(i).toLowerCase() in letras)) {
               retornoSenha = false;;
            }
        }
        return retornoSenha;
    }
 // }
