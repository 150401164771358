import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuarioService } from '../services/usuario.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { Router } from '@angular/router';
import { Pages } from 'src/app/shared/models/pages';
import { IdleService } from 'src/app/services/idle.service';
import { StringValidator } from 'src/app/common/validation/string-validator';

@Component({
  selector: 'app-esqueci-senha',
  templateUrl: './esqueci-senha.component.html',
  styleUrls: ['./esqueci-senha.component.css']
})
export class EsqueciSenhaComponent implements OnInit {
  public readonly siteKey = '6LemFCQTAAAAAFUJ_wSffxnChjJFuA7UrlF4kMBq';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  recaptchaStr: string = '';

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'pt-BR';
  public type: 'image' | 'audio';
  public useGlobalDomain = false;

  public acessoUsuario: string;
  esquecisenhaForm: FormGroup;

  @ViewChild('captchaElem', { static: true }) captchaElem: ReCaptcha2Component;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;

  get loginField() { return this.esquecisenhaForm.controls.login; }
  get emailField() { return this.esquecisenhaForm.controls.email; }
  get controls() { return this.esquecisenhaForm.controls; }

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private idleService: IdleService,
    private usuarioService: UsuarioService) { }

  ngOnInit() {
    this.esquecisenhaForm = this.formBuilder.group({
      login: ['', null],
      email: ['', Validators.email],
      recaptcha: ['', [Validators.required]]
    });
  }

  handleReset(): void {
    this.recaptchaStr = '';
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.recaptchaStr = 'ok';
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
  }

  handleExpire(): void {
    this.recaptchaStr = '';
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }

  loginFocus() {
    let value = this.loginField.value as string;

    if (value.length == 14) {
      let cpf = value.replace('.', '').replace('.', '').replace('-', '');

      if (!Number.isNaN(Number(cpf)) && cpf.length == 11)
        this.loginField.setValue(cpf);
    }
  }

  loginBlur() {
    let value = this.loginField.value as string;

    if (value.length == 11 && !Number.isNaN(Number(value))) {
      let cpf = `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`;
      this.loginField.setValue(cpf);
    }
  }

  esquciSenha() {
      if (this.recaptchaStr == 'ok')
      {
        this.usuarioService.esqueciSenha(this.emailField.value ,this.loginField.value)
        .subscribe(response => {
          this.usuarioService.handleResponse(response);
          this.router.navigate([Pages.Usuario.esqueciSenha]);
        });
      }
      
  }

}
