import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class MessageService {

    constructor(protected toastr: ToastrService) { }

    info(message: string) {
        this.toastr.info(message);
    }

    warning(message: string) {
        this.toastr.warning(message);
    }

    error(message: string) {
        this.toastr.error(message);
    }

    success(message: string) {
        this.toastr.success(message);        
    }
}