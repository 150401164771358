import { BaseService } from "src/app/services/base.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Sistema } from "src/app/services/models/constants";
import { Credencial } from "../models/credencial";
import { UsuarioApi } from "../models/usuario-api";
import { MessageService } from "src/app/services/message.service";
import { Usuario } from "../models/usuario";
import { Response } from "src/app/shared/models/response";
import { Token } from "../models/token";
import { CentroCusto } from "../models/centro-custo";
import { StringUtils } from "src/app/common/data-type-utils/string-utils";
import { debounceTime, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Mensagem } from "src/app/shared/models/mensagem";
import { UsuarioSistema } from "../../models/usuarioSistema";
import { Grupo } from "../../models/grupo";

@Injectable()
export class UsuarioService extends BaseService {
  constructor(
    protected override message: MessageService,
    protected override http: HttpClient
  ) {
    super(message, http);
  }

  autenticar(credencial: Credencial) {
    return this.http.post(this.apiUrl + UsuarioApi.autenticar, credencial);
  }

  renovarToken(login: string, usuarioSics: number, centroCustoId: number) {
    return this.http.post<Response>(this.apiUrl + UsuarioApi.renovarToken, {
      login,
      usuarioSics,
      centroCustoId,
    });
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
  }

  obterUsuarioLogado() {
    return JSON.parse(
      localStorage.getItem(Sistema.localStorage.user)
    ) as Usuario;
  }

  obterCentrosCusto(usuarioSics: string) {
    return this.http.get(
      this.apiUrl + UsuarioApi.obterCentrosCusto + usuarioSics
    );
  }

  obterCentrosCustoUsuarioId(usuarioSics: string) {
    return this.http.get(
      this.apiUrl + UsuarioApi.obterCentrosCustoUsuarioId + usuarioSics
    );
  }

  obterCentroCustoUsuarioLogado() {
    return JSON.parse(
      localStorage.getItem(Sistema.localStorage.centroCusto)
    ) as CentroCusto;
  }

  obterPermissoes(token: Token) {
    return this.http.post(this.apiUrl + UsuarioApi.obterPermissoes, token);
  }

  alterarSenha(login: string, senhaAtual: string, novaSenha: string) {
    return this.http.put<Response>(this.apiUrl + UsuarioApi.alterarSenha, {
      login,
      senhaAtual: senhaAtual.trim(),
      novaSenha: novaSenha.trim(),
    });
  }

  esqueciSenha(email: string, login: string) {
    return this.http.put<Response>(this.apiUrl + UsuarioApi.esqueciSenha, {
      email,
      login,
    });
  }

  obterUsuarioPorNomeMatricula(nomeMatricula: string): Observable<Usuario[]> {
    if (
      typeof nomeMatricula !== "string" ||
      StringUtils.isNullOrEmpty(nomeMatricula) ||
      nomeMatricula.trim().length < 3
    )
      return of(null);

    let apli = this.http
      .get<Response>(
        this.apiUrl + UsuarioApi.obterUsuarioPorNomeMatricula + nomeMatricula
      )
      .pipe(
        debounceTime(300),
        map((response: Response) => {
          let data = response.data as Usuario[];
          if (data != null && data.length != 0) return data;
          else {
            let vazio = new Usuario();
            vazio.idUsuario = 0;
            vazio.nmUsuario = Mensagem.MSG07;
            return [vazio];
          }
        })
      );

    return apli;
  }

  obterUsuariosAtribuidos(idAplicacao: number) {
    return this.http.get<Response>(
      this.apiUrl + UsuarioApi.obterUsuariosAtribuidos + idAplicacao
    );
  }

  salvarUsuarioSistema(usuarioSistema: UsuarioSistema) {
    return this.http.post<Response>(
      this.apiUrl + UsuarioApi.salvarUsuarioSistema,
      usuarioSistema
    );
  }

  excluirUsuarioSistema(usuarioSistema: UsuarioSistema) {
    return this.http.post<Response>(
      this.apiUrl + UsuarioApi.excluirUsuarioSistema,
      usuarioSistema
    );
  }

  adicionarCentroDeCusto(idUsuario: number, idCentroCusto: number) {
    return this.http.post<Response>(
      this.apiUrl + UsuarioApi.adicionarCentroDeCusto,
      {
        idUsuario,
        idCentroCusto,
      }
    );
  }

  removerCentroDeCusto(idUsuario: number, idCentroCusto: number) {
    return this.http.post<Response>(
      this.apiUrl + UsuarioApi.removerCentroDeCusto,
      {
        idUsuario,
        idCentroCusto,
      }
    );
  }

  obterPerfisUsuario(idMatricula: number, centroCustoId: number) {
    return this.http.get<Response>(
      this.apiUrl +
        UsuarioApi.obterPerfisUsuario +
        idMatricula +
        "/" +
        centroCustoId
    );
  }

  atribuirPermissaoAcesso(
    idUsuario: number,
    idCentroCusto: number,
    grupos: Grupo[]
  ) {
    return this.http.post<Response>(
      this.apiUrl + UsuarioApi.atribuirPermissaoAcesso,
      {
        idUsuario,
        idCentroCusto,
        grupos,
      }
    );
  }
}
