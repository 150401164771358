import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { debounceTime, map } from "rxjs/operators";
import { BaseService } from "src/app/services/base.service";
import { MessageService } from "src/app/services/message.service";
import { Aplicacao } from "../../models/aplicacao";
import { AplicacaoApi } from "../../models/aplicacao-api";
import { Response } from "src/app/shared/models/response";
import { StringUtils } from "src/app/common/data-type-utils/string-utils";
import { Grupo } from "../../models/grupo";
import { GrupoApi } from "../../models/grupo-api";
import { GrupoSistema } from "../../models/grupoSistema";
import { Mensagem } from "src/app/shared/models/mensagem";

@Injectable()
export class VincularGrupoSistemaService extends BaseService {
  constructor(
    protected override message: MessageService,
    protected override http: HttpClient
  ) {
    super(message, http);
  }

  obterAplicacaoPorNome(
    nmAplicacao: string,
    idUsuario: number = 0
  ): Observable<Aplicacao[]> {
    if (
      typeof nmAplicacao !== "string" ||
      StringUtils.isNullOrEmpty(nmAplicacao) ||
      nmAplicacao.trim().length < 3
    )
      return of(null);

    let url = this.apiUrl + AplicacaoApi.obterAplicacaoPorNome + nmAplicacao;

    if (idUsuario > 0) url += "/" + idUsuario;

    let apli = this.http.get<Response>(url).pipe(
      debounceTime(300),
      map((response: Response) => {
        let data = response.data as Aplicacao[];
        if (data != null && data.length != 0) return data;
        else {
          let vazio = new Aplicacao();
          vazio.idAplicacao = 0;
          vazio.nmAplicacao = Mensagem.MSG06;
          vazio.sgAplicacao = "";
          return [vazio];
        }
      })
    );

    return apli;
  }

  obterGrupoPorNome(
    nmGrupo: string,
    idAplicacao: number = 0
  ): Observable<Grupo[]> {
    if (
      typeof nmGrupo !== "string" ||
      StringUtils.isNullOrEmpty(nmGrupo) ||
      nmGrupo.trim().length < 3
    )
      return of(null);

    let apli = null;
    if (idAplicacao == 0) {
      apli = this.http
        .get<Response>(this.apiUrl + GrupoApi.obterGrupoPorNome + nmGrupo)
        .pipe(
          debounceTime(300),
          map((response: Response) => {
            let data = response.data as Grupo[];
            if (data != null && data.length != 0) return data;
          })
        );
    } else {
      apli = this.http
        .get<Response>(
          this.apiUrl + GrupoApi.obterGrupoPorNome + nmGrupo + "/" + idAplicacao
        )
        .pipe(
          debounceTime(300),
          map((response: Response) => {
            let data = response.data as Grupo[];
            if (data != null && data.length != 0) return data;
          })
        );
    }

    return apli;
  }

  obterAplicacao(idUsuario: number = 0) {
    let url = this.apiUrl + AplicacaoApi.obterAplicacao;

    if (idUsuario > 0) url += idUsuario;

    return this.http.get<Response>(url);
  }

  obterGrupoAtribuidos(idAplicacao: number) {
    return this.http.get<Response>(
      this.apiUrl + GrupoApi.obterGruposAtribuidos + idAplicacao
    );
  }

  obterGruposDisponiveis(idAplicacao: number) {
    return this.http.get<Response>(
      this.apiUrl + GrupoApi.obterGruposDisponiveis + idAplicacao
    );
  }

  salvarGrupoSistema(grupoSistema: GrupoSistema) {
    return this.http.post<Response>(
      this.apiUrl + AplicacaoApi.salvarGrupoSistema,
      grupoSistema
    );
  }

  obterGruposSistemas(idsSistemas: number[]) {
    return this.http.post<Response>(
      this.apiUrl + AplicacaoApi.obterGruposSistemas,
      idsSistemas
    );
  }
}
