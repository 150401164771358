import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioService } from '../services/usuario.service';
import { Pages } from 'src/app/shared/models/pages';

@Component({
  selector: 'app-topo-login',
  templateUrl: './topo-login.component.html',
  styleUrls: ['./topo-login.component.scss']
})
export class TopoLoginComponent implements OnInit {

  @Input() exibirLinkSair: boolean = false;

  constructor(private usuarioService: UsuarioService,
    private router: Router) { }

  ngOnInit() {
  }

  sair() {
    this.usuarioService.logout();
    this.router.navigate([Pages.Usuario.login]);
  }
}
