import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Observable } from "rxjs";
import {
  startWith,
  distinctUntilChanged,
  tap,
  switchMap,
  finalize,
} from "rxjs/operators";
import { MessageService } from "src/app/services/message.service";
import { ConfirmComponent } from "src/app/shared/confirm/confirm.component";
import { Mensagem } from "src/app/shared/models/mensagem";
import { Aplicacao } from "../models/aplicacao";
import { UsuarioSistema } from "../models/usuarioSistema";
import { Usuario } from "../usuario/models/usuario";
import { UsuarioService } from "../usuario/services/usuario.service";
import { VincularGrupoSistemaService } from "../vincular-grupo-sistema/Services/vincular-grupo-sistema.service";
import { Options } from "select2";
import { CentroCusto } from "../usuario/models/centro-custo";

@Component({
  selector: "app-vincular-usuario-gestor-sistema",
  templateUrl: "./vincular-usuario-gestor-sistema.component.html",
  styleUrls: ["./vincular-usuario-gestor-sistema.component.scss"],
})
export class VincularUsuarioGestorSistemaComponent implements OnInit {
  consultarDadosForm = new FormGroup({
    nmSistema: new FormControl(),
    nmUsuario: new FormControl(),
    selectOU: new FormControl(),
  });

  sistemaCarregando: boolean = false;
  usuarioCarregando: boolean = false;
  descricaoSistema: string = "";
  sistemaSelecionado: Aplicacao = new Aplicacao();
  centroCustoSelecionado: CentroCusto = new CentroCusto();
  usuarioSelecionado: Usuario = new Usuario();
  btnSalvar: boolean = true;
  btnVincular: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  usuarioRelacionados: Usuario[] = new Array<Usuario>();

  sistemasObservable: Observable<any[]>;
  usuariosObservable: Observable<any[]>;

  displayedColumns = ["nuMatricula", "nmUsuario", "acoes"];
  dataSourceUsuarios = new MatTableDataSource<Usuario>(
    this.usuarioRelacionados
  );

  pageSize = 10;
  pageSizeOptions = [this.pageSize];

  lengthUsuarios = this.usuarioRelacionados.length;

  public options: Options;
  sistemas: Aplicacao[] = new Array<Aplicacao>();
  orgaosUnidades: CentroCusto[] = new Array<CentroCusto>();

  private _value: string;
  private _valueOU: string;

  constructor(
    private vincularGrupoSistemaService: VincularGrupoSistemaService,
    private usuarioService: UsuarioService,
    private messageService: MessageService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.sistemaInitAutocomplete();
    this.usuarioIniAutoComplete();
    this.obterSistemas();

    let mensagem = Mensagem.MSG06;

    this.options = {
      theme: "classic",
      width: "500",
      language: {
        noResults: () => {
          return mensagem;
        },
      },
    };
  }

  get controls() {
    return this.consultarDadosForm.controls;
  }

  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this.selecionarSistemaResponsavelId(value);
    this._value = value;
  }

  get valueOu(): string {
    return this._valueOU;
  }
  set valueOu(valueOu: string) {
    this.selecionarCentroCusto(valueOu);
    this._valueOU = valueOu;
  }

  obterSistemas() {
    this.vincularGrupoSistemaService.obterAplicacao().subscribe((response) => {
      if (response)
        this.sistemas = this.vincularGrupoSistemaService.handleResponse(
          response
        ) as Aplicacao[];
    });
  }

  obterOrgaosUnidades() {
    if (this.usuarioSelecionado.idUsuario > 0) {
      this.usuarioService
        .obterCentrosCustoUsuarioId(
          this.usuarioSelecionado.idUsuario.toString()
        )
        .subscribe((response) => {
          this.orgaosUnidades = this.usuarioService.handleResponse(
            response
          ) as Array<CentroCusto>;
        });
    }
  }

  visualizarSistemaSelecionado(aplicacao: Aplicacao) {
    if (aplicacao && aplicacao.idAplicacao != 0)
      return aplicacao.sgAplicacao + " - " + aplicacao.nmAplicacao;
    else return "";
  }

  visualizarUsuarioSelecionado(usuario: Usuario) {
    if (usuario && usuario.idUsuario != 0)
      return usuario.nmUsuario + " - " + usuario.nmLogin;
    else return "";
  }

  selecionarSistemaResponsavel(aplicacao: Aplicacao) {
    this.descricaoSistema = "";
    console.log("Sistema selecionado => ", aplicacao);
    this.descricaoSistema = aplicacao.dsAplicacao;
    this.sistemaSelecionado = aplicacao;

    if (this.sistemaSelecionado.idAplicacao > 0) {
      this.obterUsuariosAtribuidos(this.sistemaSelecionado.idAplicacao);
    }
  }

  selecionarCentroCusto(codigo: string) {
    var cc = this.orgaosUnidades.find((x) => x.codigo == parseInt(codigo));

    this.centroCustoSelecionado = cc;
    console.log("centro selecionado => ", this.centroCustoSelecionado);
    
  }

  selecionarSistemaResponsavelId(idAplicacao: string) {
    this.descricaoSistema = "";
    var aplicacao = this.sistemas.find(
      (x) => x.idAplicacao == parseInt(idAplicacao)
    );
    console.log("Sistema selecionado => ", aplicacao);

    this.descricaoSistema = aplicacao.dsAplicacao;
    this.sistemaSelecionado = aplicacao;

    if (this.sistemaSelecionado.idAplicacao > 0) {
      
      this.obterUsuariosAtribuidos(this.sistemaSelecionado.idAplicacao);
    }
  }

  private obterUsuariosAtribuidos(idAplicacao: number) {
    this.usuarioService
      .obterUsuariosAtribuidos(idAplicacao)
      .subscribe((Response) => {
        this.usuarioRelacionados =
          this.vincularGrupoSistemaService.handleResponse(
            Response
          ) as Usuario[];
        //this.gruposNaoAtribuidosAux = this.gruposAtribuidos;
        this.carregarGridUsuarios();
      });
  }

  selecionarUsuario(usuario: Usuario) {
    console.log("usuario selecionado => ", usuario);
    this.usuarioSelecionado = new Usuario();

    this.usuarioSelecionado = usuario;
    
    this.obterOrgaosUnidades();
  }

  carregarGridUsuarios() {
    this.dataSourceUsuarios = new MatTableDataSource<Usuario>(
      this.usuarioRelacionados
    );
    this.dataSourceUsuarios.paginator = this.paginator;

    this.dataSourceUsuarios.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "nmGrupo":
          return item.nmUsuario.toUpperCase();
        default:
          return item[property];
      }
    };

    this.dataSourceUsuarios.sort = this.sort;
  }

  sistemaInitAutocomplete() {
    this.sistemasObservable = this.controls.nmSistema.valueChanges.pipe(
      startWith(null),
      distinctUntilChanged(),
      tap(() => {
        this.sistemaCarregando = true;
      }),
      switchMap((value) => {
        return this.vincularGrupoSistemaService
          .obterAplicacaoPorNome(value || "")
          .pipe(
            finalize(() => {
              this.sistemaCarregando = false;
            })
          );
      })
    );
  }

  usuarioIniAutoComplete() {
    this.usuariosObservable = this.controls.nmUsuario.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => {
        this.usuarioCarregando = true;
      }),
      switchMap((value) => {
        return this.usuarioService
          .obterUsuarioPorNomeMatricula(value || "")
          .pipe(
            finalize(() => {
              this.usuarioCarregando = false;
            })
          );
      })
    );
  }

  salvar() {
    if (
      this.sistemaSelecionado.idAplicacao > 0 &&
      this.centroCustoSelecionado.codigo > 0
    ) {
      let usuarioSistema = new UsuarioSistema();

      usuarioSistema.idSistema = this.sistemaSelecionado.idAplicacao;
      usuarioSistema.codigoCentro = this.centroCustoSelecionado.codigo;
      usuarioSistema.usuario = this.usuarioRelacionados;

      this.usuarioService
        .salvarUsuarioSistema(usuarioSistema)
        .subscribe((response) => {
          if (response) {
            this.messageService.success("Usuários associados com sucesso !");
          } else {
            this.messageService.error(response.errors[0]);
          }
        });
    }
  }

  VincularGestor() {
    if (this.usuarioSelecionado.idUsuario > 0) {
      let usuario = this.usuarioSelecionado;
      
      this.btnSalvar = false;
      if (!this.usuarioRelacionados.find((x) => x.idUsuario == usuario.idUsuario))
        this.usuarioRelacionados.push(usuario);
      
      this.controls.nmUsuario.patchValue("");
      this.carregarGridUsuarios();
      
      this.btnSalvar = false;
    }
  }

  excluirUsuario(usuario: Usuario) {
    let mensagem = Mensagem.MSE01.replace(
      "{Matricula - Nome}",
      usuario.nuMatricula + " - " + usuario.nmUsuario
    ).replace(
      "{Sigla – Nome completo}",
      this.sistemaSelecionado.sgAplicacao +
        " - " +
        this.sistemaSelecionado.nmAplicacao
    );

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: "450px",
      height: "220px",
      data: { message: mensagem },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let index = this.usuarioRelacionados.findIndex(
          (x) => x.idUsuario == usuario.idUsuario
        );
        if (index >= 0) {
          this.usuarioRelacionados.splice(index, 1);
          this.carregarGridUsuarios();
          let usuarioSistema = new UsuarioSistema();

          usuarioSistema.idSistema = this.sistemaSelecionado.idAplicacao;
          usuarioSistema.codigoCentro = this.centroCustoSelecionado.codigo;
          usuarioSistema.usuario.unshift(usuario);

          this.usuarioService
            .excluirUsuarioSistema(usuarioSistema)
            .subscribe((response) => {
              if (response) {
                this.messageService.success("Usuário excluído com sucesso !");
              } else {
                this.messageService.error(response.errors[0]);
              }
            });
        }
      }
    });
  }
}
