import { RecaptchaComponent } from "ng-recaptcha";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  RequiredValidator,
} from "@angular/forms";
import { UsuarioService } from "../services/usuario.service";
import { Credencial } from "../models/credencial";
import { Usuario } from "../models/usuario";
import { Sistema } from "src/app/services/models/constants";
import { Router } from "@angular/router";
import { Pages } from "src/app/shared/models/pages";
import { StringUtils } from "src/app/common/data-type-utils/string-utils";
import { CentroCusto } from "../models/centro-custo";
import { IdleService } from "src/app/services/idle.service";
import { StringValidator } from "src/app/common/validation/string-validator";
import { MatButton } from "@angular/material/button";
//import { MessageService } from 'src/app/services/message.service';
import { ToastrService } from "ngx-toastr";
import * as CryptoJS from "crypto-js";
import { Mensagem } from "src/app/shared/models/mensagem";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public readonly siteKey = "6LemFCQTAAAAAFUJ_wSffxnChjJFuA7UrlF4kMBq";
  loginForm: FormGroup;
  qtdeTentativasLogin = 0;
  recaptchaStr = "";
  submitted = false;
  mensagem = Mensagem;
  public captchaIsExpired = false;

  public theme: "light" | "dark" = "light";
  public size: "compact" | "normal" = "normal";
  public lang = "pt-BR";
  public type: "image" | "audio";
  public useGlobalDomain = false;

  @ViewChild(RecaptchaComponent, { static: false })
  reCaptcha: RecaptchaComponent;
  @ViewChild("langInput", { static: false }) langInput: ElementRef;
  @ViewChild(MatButton, { static: true }) botaoLogin: MatButton;

  constructor(
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private router: Router,
    //private messageService: MessageService,
    private toastr: ToastrService,
    private idleService: IdleService
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      login: ["", [Validators.required, StringValidator.minLength(1)]],
      senha: ["", [Validators.required, StringValidator.minLength(1)]],
    });
  }

  get controls() {
    return this.loginForm.controls;
  }
  get loginField() {
    return this.loginForm.controls.login;
  }

  login() {
    this.submitted = true;
    var login = this.loginForm.controls.login.value;
    var senha = this.loginForm.controls.senha.value;

    if (
      login != "" &&
      senha != "" &&
      (this.qtdeTentativasLogin < 3 ||
        (this.qtdeTentativasLogin >= 3 && this.recaptchaStr == "ok"))
    ) {
      this.qtdeTentativasLogin = this.qtdeTentativasLogin + 1;
      let credencial = new Credencial();
      credencial.login = this.encriptar(login);
      credencial.senha = this.encriptar(senha);

      this.usuarioService.autenticar(credencial).subscribe((response) => {
        let usuario = this.usuarioService.handleResponse(response) as Usuario;

        if (usuario && usuario.autenticado) {
          this.usuarioAutenticado(usuario);
        } else {
          if (this.qtdeTentativasLogin >= 3) {
            this.reCaptcha.execute();
            this.reCaptcha.reset();

            // this.loginForm = this.formBuilder.group({
            //   login: ['', [Validators.required, StringValidator.minLength(1)]],
            //   senha: ['', [Validators.required, StringValidator.minLength(6)]],
            //   reCaptcha: ['', [Validators.required]]
            // });
          }

          this.toastr.error(Mensagem.loginInvalido);
          //this.messageService.error(Mensagem.loginInvalido);
        }

        this.recaptchaStr = "";
        // this.loginForm.get('login').setValue(login);
        // this.loginForm.get('senha').setValue("");
      });
    }
  }

  usuarioAutenticado(usuario: Usuario) {
    this.idleService.reset();

    localStorage.setItem(
      Sistema.localStorage.token,
      JSON.stringify(usuario.token)
    );
    console.log("cc -> " + usuario.centroCusto);
    if (!StringUtils.isNullOrEmpty(usuario.centroCusto)) {
      let centroCusto = new CentroCusto();
      centroCusto.codigo = usuario.centroCustoId;
      centroCusto.nome = usuario.centroCusto;
      centroCusto.nomeMunicipio = usuario.nomeMunicipio;
      usuario.claims.forEach((c) => {
        if (c.tipo.includes("<br>")) {
          var re = /<br>/gi;
          c.tipo = c.tipo.replace(re, "");
        }
      });
      localStorage.setItem(Sistema.localStorage.user, JSON.stringify(usuario));
      localStorage.setItem(
        Sistema.localStorage.centroCusto,
        JSON.stringify(centroCusto)
      );

      this.router.navigate([Pages.Principal.home]);
    } else {
      usuario.claims.forEach((c) => {
        if (c.tipo.includes("<br>")) {
          var re = /<br>/gi;
          c.tipo = c.tipo.replace(re, "");
        }
      });
      localStorage.setItem(Sistema.localStorage.user, JSON.stringify(usuario));
      this.router.navigate([Pages.Usuario.centroCusto]);
    }
  }

  loginFocus() {
    let value = this.loginField.value as string;

    if (value.length == 14) {
      let cpf = value.replace(".", "").replace(".", "").replace("-", "");

      if (!Number.isNaN(Number(cpf)) && cpf.length == 11)
        this.loginField.setValue(cpf);
    }
  }

  loginBlur() {
    let value = this.loginField.value as string;

    if (value.length == 11 && !Number.isNaN(Number(value))) {
      let cpf = `${value.substring(0, 3)}.${value.substring(
        3,
        6
      )}.${value.substring(6, 9)}-${value.substring(9, 11)}`;
      this.loginField.setValue(cpf);
    }
  }

  public resolved(captchaResponse: string): void {
    this.recaptchaStr = "ok";
    if (this.recaptchaStr) {
      this.botaoLogin.disabled = false;
    }
  }

  encriptar(valor: string): string {
    let secretKey = "qSJtAVl9wgQIiABi";
    let key = CryptoJS.enc.Utf8.parse(secretKey);
    let iv = CryptoJS.enc.Utf8.parse(secretKey);

    let settings = {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    };

    return CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(valor),
      key,
      settings
    ).toString();
  }

  handleExpire(): void {
    this.recaptchaStr = "ok";
    this.captchaIsExpired = true;
  }
}
