import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { UsuarioService } from 'src/app/SICSv2/usuario/services/usuario.service';
import { Claim } from 'src/app/SICSv2/usuario/models/claim';
import { Router } from '@angular/router';
import { Pages } from '../models/pages';
declare var $: any;

@Component({
  selector: 'app-menu-superior',
  templateUrl: './menu-superior.component.html',
  styleUrls: ['./menu-superior.component.scss']
})
export class MenuSuperiorComponent implements OnInit {
  public isNavbarCollapsed: boolean;
  public nomeUsuario: string;
  public centroCusto: string;
  public nomeMunicipio: string;

  public consultarUsuarioAd: boolean;
  public relatoriousuarioAd: boolean;
  public vincularGrupoSistema: boolean;
  public vincularUsuarioGestorSistema: boolean;
  public vincularUsuarioOrgaoUnidade: boolean;
  public atribuirPermissaoAcesso: boolean;

  permissoesUsuario: Claim[];

  constructor(private usuarioService: UsuarioService, private router: Router) { }

  ngOnInit() {

    let usuario = this.usuarioService.obterUsuarioLogado();
    if (usuario) {
      this.nomeUsuario = usuario.nome;
      this.permissoesUsuario = usuario.claims;
      this.carregarPermissoesAcessos();
    }

    this.obterCentroCustoServidor();

    let _this = this;

    $(document).ready(function(){
    
      $("#menu-toggle").click(function (e) {
        e.preventDefault();
        //$("#wrapper").toggleClass("active");
        $(".esconder_comprimido").toggle(0);        
        //inverter seta
        if ($(".esconder_comprimido").is(":visible")) {
          $("#svgSeta").css("transform", "scale(-1,1)");          
          localStorage.setItem("mostrar_side_bar", 'true');
          //$("#ultimalinha").css("width", "");            
        } else {
          $("#svgSeta").css("transform", "scale(1,1)");          
          //$("#ultimalinha").css("width", "");            
          localStorage.setItem("mostrar_side_bar", 'false');
        }

        _this.replicaLarguraLinha();
      });

    });
  }

  private replicaLarguraLinha() {
    $("#sidebar-logoff").width($("#sidebartop").width());
    $("#ctl00_lblUser").width(150);
  }

  obterCentroCustoServidor() {
    let centroCusto = this.usuarioService.obterCentroCustoUsuarioLogado();
    if (centroCusto)
      this.centroCusto = centroCusto.nome;
    this.nomeMunicipio = centroCusto.nomeMunicipio;
  }

  verificarPermissao(tipo: string, valor: string) {
    let index = this.permissoesUsuario.findIndex(x => x.tipo == tipo && x.valor == valor);
    return index >= 0;
  }

  sair() {
    this.usuarioService.logout();
    this.router.navigate([Pages.Usuario.login]);
  }
 
  ngAfterViewChecked() {
   
    var mostrar_side_bar = localStorage.getItem("mostrar_side_bar");
    if (mostrar_side_bar == "true") {
      $(".esconder_comprimido").show();
      $("#svgSeta").css("transform", "scale(-1,1)");
    }
  }

  private carregarPermissoesAcessos()
  {
    this.consultarUsuarioAd = this.verificarPermissao('consultar-usuarios-ad','visualizar');  
    this.relatoriousuarioAd = this.verificarPermissao('relatorio-desativacao-usuario-ad','visualizar');
    this.vincularGrupoSistema = this.verificarPermissao('vincular-grupo-sistema','visualizar');
    this.vincularUsuarioGestorSistema = this.verificarPermissao('vincular-usuario-gestor-sistema','visualizar');
    this.vincularUsuarioOrgaoUnidade = this.verificarPermissao('vincular-usuario-orgao-unidade','visualizar');
    this.atribuirPermissaoAcesso = this.verificarPermissao('atribuir-permissao-acesso','visualizar'); 
  }

}
