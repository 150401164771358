import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'protocolo'
})
export class ProtocoloPipe implements PipeTransform {

    transform(value: number, args: any[]): string {        
        if (value === null || value === undefined) return '';
        let result: string = value.toString();
        
        while (result.length < 6)
            result = "0" + result;

        return result;
    }

}