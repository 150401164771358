// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-page {
  padding-bottom: 0px;
}

.table-cor-cabecalho {
  background-color: #33b5ea !important;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/SICSv2/usuario/centro-custo/centro-custo.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,oCAAA;EACA,YAAA;AACJ","sourcesContent":[".content-page{\r\n    padding-bottom: 0px;\r\n}\r\n\r\n.table-cor-cabecalho {\r\n    background-color: #33b5ea !important;\r\n    color: white;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
