import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { distinctUntilChanged, tap, switchMap, finalize } from "rxjs/operators";
import { MessageService } from "src/app/services/message.service";
import { ConfirmComponent } from "src/app/shared/confirm/confirm.component";
import { Mensagem } from "src/app/shared/models/mensagem";
import { VincularOrgaoUnidadeModalComponent } from "src/app/shared/vincular-orgao-unidade-modal/vincular-orgao-unidade-modal.component";
import { Aplicacao } from "../models/aplicacao";
import { Grupo } from "../models/grupo";
import { Perfil } from "../models/perfil";
import { CentroCusto } from "../usuario/models/centro-custo";
import { Usuario } from "../usuario/models/usuario";
import { UsuarioService } from "../usuario/services/usuario.service";
import { VincularGrupoSistemaService } from "../vincular-grupo-sistema/Services/vincular-grupo-sistema.service";
import { VincularOrgaoUnidadeUsuarioComponent } from "../vincular-orgao-unidade-usuario/vincular-orgao-unidade-usuario.component";
import { Options } from "select2";
import { AplicacaoGrupos } from "../models/aplicacao-grupo";

@Component({
  selector: "app-atribuir-permissao-por-aplicacao",
  templateUrl: "./atribuir-permissao-por-aplicacao.component.html",
  styleUrls: ["./atribuir-permissao-por-aplicacao.component.scss"],
})
export class AtribuirPermissaoPorAplicacaoComponent implements OnInit {
  consultarDadosForm = new FormGroup({
    nmSistema: new FormControl(),
    nmUsuario: new FormControl(),
    nmGrupo: new FormControl(),
  });

  highlightedRows = [];

  usuarioCarregando: boolean = false;
  sistemaCarregando: boolean = false;
  btnSalvar: boolean = true;
  btnVincular: boolean = true;
  dadosUsuario: boolean = false;
  grupoCarregando: boolean = false;
  sistemaDisable: boolean = true;

  tipoUsuario: string = "";
  loginUsuario: string = "";
  orgaoOrigemUsuario: string = "";

  @ViewChild("paginatorVinculado") paginatorVinculado: MatPaginator;
  @ViewChild("paginatorGrupoNaoVinculado")
  paginatorGrupoNaoVinculado: MatPaginator;
  @ViewChild("paginatorGrupoVinculado") paginatorGrupoVinculado: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  orgaoUnidadeVinculados: Array<CentroCusto> = new Array<CentroCusto>();

  centroCustoSelecionado: CentroCusto = new CentroCusto();

  gruposNaoAtribuidos: Array<Grupo> = new Array<Grupo>();
  gruposAtribuidos: Array<Grupo> = new Array<Grupo>();
  gruposAtribuidosListar: Array<Grupo> = new Array<Grupo>();

  gruposNaoAtribuidosSelecionados: Array<Grupo> = new Array<Grupo>();
  gruposAtribuidosSelecionados: Array<Grupo> = new Array<Grupo>();
  gruposNaoAtribuidosAux: Array<Grupo> = new Array<Grupo>();

  lengthOrgaoUnidadeVinculado = this.orgaoUnidadeVinculados.length;
  lengthAssociados = this.gruposAtribuidos.length;
  lengthDisponivel = this.gruposNaoAtribuidos.length;

  pageSize = 10;
  pageSizeOptions = [this.pageSize];

  displayedOrgaoColumns = ["nmOrgao", "stTipo", "acoes"];
  displayedColumns = ["nmGrupo", "acoes"];

  dataSourceAtribuidos = new MatTableDataSource<Grupo>(this.gruposAtribuidos);
  dataSourceNaoAtribuidos = new MatTableDataSource<Grupo>(
    this.gruposNaoAtribuidos
  );
  dataSourceOrgaoUndidadesVinculados = new MatTableDataSource<CentroCusto>(
    this.orgaoUnidadeVinculados
  );

  sistemaSelecionado: Aplicacao = new Aplicacao();

  usuarioSelecionado: Usuario = new Usuario();

  usuariosObservable: Observable<any[]>;
  sistemasObservable: Observable<any[]>;

  public options: Options;
  sistemas: Aplicacao[] = new Array<Aplicacao>();

  private _value: string;

  constructor(
    private usuarioService: UsuarioService,
    private vincularGrupoSistemaService: VincularGrupoSistemaService,
    private messageService: MessageService,
    public dialog: MatDialog,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.sistemaInitAutocomplete();
    this.usuarioIniAutoComplete();
    this.grupoInitAutoComplete();

    this.controls.nmSistema.disable();
    this.controls.nmGrupo.disable();
    this.obterSistemas();

    let mensagem = Mensagem.MSG06;

    this.options = {
      theme: "classic",
      width: "500",
      language: {
        noResults: function () {
          return mensagem;
        },
      },
    };
  }

  get controls() {
    return this.consultarDadosForm.controls;
  }

  limparCampos() {
    this.centroCustoSelecionado = new CentroCusto();

    this.gruposNaoAtribuidos = new Array<Grupo>();
    this.gruposAtribuidos = new Array<Grupo>();

    this.gruposNaoAtribuidosSelecionados = new Array<Grupo>();
    this.gruposAtribuidosSelecionados = new Array<Grupo>();
    this.gruposNaoAtribuidosAux = new Array<Grupo>();

    this.dataSourceAtribuidos = new MatTableDataSource<Grupo>(
      this.gruposAtribuidos
    );
    this.dataSourceNaoAtribuidos = new MatTableDataSource<Grupo>(
      this.gruposNaoAtribuidos
    );
    this.dataSourceOrgaoUndidadesVinculados =
      new MatTableDataSource<CentroCusto>(this.orgaoUnidadeVinculados);

    this.sistemaSelecionado = new Aplicacao();
  }
  visualizarUsuarioSelecionado(usuario: Usuario) {
    if (usuario && usuario.idUsuario != 0)
      return usuario.nmUsuario + " - " + usuario.nmLogin;
    else return "";
  }

  visualizarSistemaSelecionado(aplicacao: Aplicacao) {
    if (aplicacao && aplicacao.idAplicacao != 0)
      return aplicacao.sgAplicacao + " - " + aplicacao.nmAplicacao;
    else return "";
  }

  selecionarUsuario(usuario: Usuario) {
    console.log("usuario selecionado => ", usuario);
    this.usuarioSelecionado = new Usuario();

    this.usuarioSelecionado = usuario;
    this.dadosUsuario = false;
    this.btnSalvar = true;
    this.btnVincular = true;

    if (usuario.idUsuario > 0) {
      this.dadosUsuario = true;
      //this.tipoUsuario = usuario.
      this.loginUsuario = usuario.nmLogin;
      this.orgaoOrigemUsuario = usuario.centroCusto;

      this.controls.nmSistema.enable();
      this.controls.nmGrupo.enable();
      this.limparCampos();

      //this.controls.descOrgao.enable();
      //this.btnSalvar = false;

      this.controls.nmUsuario.patchValue("");
      this.obterOrgaoUnidadeVinculados(usuario.idUsuario.toString());
    } else {
      this.controls.nmSistema.disable();
      this.controls.nmGrupo.disable();
    }
  }

  private obterOrgaoUnidadeVinculados(usuarioSics: string) {
    this.usuarioService
      .obterCentrosCustoUsuarioId(usuarioSics)
      .subscribe((response) => {
        this.orgaoUnidadeVinculados = this.usuarioService.handleResponse(
          response
        ) as Array<CentroCusto>;
        if (this.orgaoUnidadeVinculados) {
          if (this.orgaoUnidadeVinculados.length == 0) {
            this.sistemaDisable = true;
          }

          this.preencheGridOrgaoUndidadesVinculados();
        }
      });
  }

  private preencheGridOrgaoUndidadesVinculados() {
    var resArr = [];
    this.orgaoUnidadeVinculados.filter(function (item) {
      var i = resArr.findIndex((x) => x.codigo == item.codigo);
      if (i <= -1) {
        resArr.push(item);
      }
      return null;
    });

    console.log("unique: " + resArr);
    this.dataSourceOrgaoUndidadesVinculados =
      new MatTableDataSource<CentroCusto>(resArr);
    this.dataSourceOrgaoUndidadesVinculados.sort = this.sort;
    this.dataSourceOrgaoUndidadesVinculados.paginator = this.paginatorVinculado;
  }

  removerOrgaoUsuario(centroCusto: CentroCusto) {
    let mensagem = Mensagem.MSE04.replace(
      "{Descricao}",
      centroCusto.nome
    ).replace(
      "{Nome do usuario - Matricula}",
      this.usuarioSelecionado.nmUsuario +
        " - " +
        this.usuarioSelecionado.nuMatricula
    );

    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: "450px",
      height: "220px",
      data: { message: mensagem },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let index = this.orgaoUnidadeVinculados.findIndex(
          (x) => x.codigo == centroCusto.codigo
        );

        if (index >= 0) {
          var orgaoUnidade = this.orgaoUnidadeVinculados.find(
            (x) => x.codigo == centroCusto.codigo
          );
          this.usuarioService
            .removerCentroDeCusto(
              this.usuarioSelecionado.idUsuario,
              orgaoUnidade.codigo
            )
            .subscribe((response) => {
              if (response) {
                this.obterOrgaoUnidadeVinculados(
                  this.usuarioSelecionado.idUsuario.toString()
                );
                this.messageService.success(
                  "Orgão Unidade removido com sucesso !"
                );
              } else {
                this.messageService.error(response.errors[0]);
              }
            });
        }
      }
    });
  }

  selecionarSistemaResponsavel(aplicacao: Aplicacao) {
    this.sistemaSelecionado = aplicacao;

    if (this.sistemaSelecionado.idAplicacao > 0) {
      this.obterGruposAtribuidos(this.sistemaSelecionado.idAplicacao);
      let dsgrupo = this.controls.nmGrupo.value;
      if (dsgrupo) this.controls.nmGrupo.setValue(dsgrupo, { emitEvent: true });
    }
  }

  selecionarSistemaResponsavelId(idAplicacao: string) {
    var aplicacao = this.sistemas.find(
      (x) => x.idAplicacao == parseInt(idAplicacao)
    );
    console.log("Sistema selecionado => ", aplicacao);

    this.sistemaSelecionado = aplicacao;
    this.btnSalvar = true;
    if (
      this.sistemaSelecionado != undefined &&
      this.sistemaSelecionado.idAplicacao > 0
    ) {
      this.obterGruposAtribuidos(this.sistemaSelecionado.idAplicacao);
      //this.btnSalvar = false;
      let dsgrupo = this.controls.nmGrupo.value;
      if (dsgrupo) this.controls.nmGrupo.setValue(dsgrupo, { emitEvent: true });
    }
  }

  usuarioIniAutoComplete() {
    this.usuariosObservable = this.controls.nmUsuario.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => {
        this.usuarioCarregando = true;
      }),
      switchMap((value) => {
        return this.usuarioService
          .obterUsuarioPorNomeMatricula(value || "")
          .pipe(
            finalize(() => {
              this.usuarioCarregando = false;
            })
          );
      })
    );
  }

  obterSistemas() {
    let usuario = this.usuarioService.obterUsuarioLogado();
    console.log(usuario);
    this.vincularGrupoSistemaService
      .obterAplicacao(usuario.usuarioSics)
      .subscribe((response) => {
        if (response)
          this.sistemas = this.vincularGrupoSistemaService.handleResponse(
            response
          ) as Aplicacao[];
      });
  }

  get value(): string {
    return this._value;
  }
  set value(value: string) {
    this.selecionarSistemaResponsavelId(value);
    this._value = value;
  }

  sistemaInitAutocomplete() {
    this.sistemasObservable = this.controls.nmSistema.valueChanges.pipe(
      distinctUntilChanged(),
      tap(() => {
        this.sistemaCarregando = true;
      }),
      switchMap((value) => {
        return this.vincularGrupoSistemaService
          .obterAplicacaoPorNome(value || "", this.usuarioSelecionado.idUsuario)
          .pipe(
            finalize(() => {
              this.sistemaCarregando = false;
            })
          );
      })
    );
  }

  grupoInitAutoComplete() {
    this.controls.nmGrupo.valueChanges
      .pipe(
        tap(() => {
          this.grupoCarregando = true;
        })
      )
      .subscribe((value) => {
        this.gruposNaoAtribuidos = this.gruposNaoAtribuidosAux;
        this.gruposNaoAtribuidos = this.gruposNaoAtribuidos.filter((grupo) => {
          return (
            grupo.nmGrupo.toLowerCase().includes(value) ||
            grupo.sgGrupo.toLowerCase().includes(value)
          );
        });
        if (this.gruposNaoAtribuidos.length > 0)
          this.carregarGridGpNaoAtribuidos();
      });
  }

  carregarGridGpNaoAtribuidos() {
    this.dataSourceNaoAtribuidos = new MatTableDataSource<Grupo>(
      this.gruposNaoAtribuidos
    );
    this.dataSourceNaoAtribuidos.paginator = this.paginatorGrupoNaoVinculado;

    this.dataSourceNaoAtribuidos.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "nmGrupo":
          return item.nmGrupo.toUpperCase();
        default:
          return item[property];
      }
    };

    this.dataSourceNaoAtribuidos.sort = this.sort;
  }

  private obterGruposAtribuidos(idAplicacao: number) {
    this.vincularGrupoSistemaService
      .obterGrupoAtribuidos(idAplicacao)
      .subscribe((Response) => {
        this.gruposNaoAtribuidos = [];
        this.gruposNaoAtribuidosAux =
          this.vincularGrupoSistemaService.handleResponse(Response) as Grupo[];
        this.gruposNaoAtribuidosAux.forEach((grupo) => {
          if (
            this.gruposAtribuidos.findIndex((x) => x.idGrupo == grupo.idGrupo) <
            0
          ) {
            this.gruposNaoAtribuidos.unshift(grupo);
          }
          else
          {
            this.gruposAtribuidosListar.unshift(grupo);
          }
        });
        this.gruposNaoAtribuidosAux = this.gruposNaoAtribuidos;
        this.carregarGridGpAtribuidos();
        this.carregarGridGpNaoAtribuidos();
      });
  }

  carregarGridGpAtribuidos() {
    this.dataSourceAtribuidos = new MatTableDataSource<Grupo>(
      this.gruposAtribuidosListar
    );
    this.dataSourceAtribuidos.paginator = this.paginatorGrupoVinculado;

    this.dataSourceAtribuidos.sortingDataAccessor = (item, property) => {
      switch (property) {
        case "nmGrupo":
          return item.nmGrupo.toUpperCase();
        default:
          return item[property];
      }
    };

    this.dataSourceAtribuidos.sort = this.sort;
  }

  salvar() {
    if (
      this.usuarioSelecionado.idUsuario > 0 &&
      this.gruposAtribuidos.length > 0
    ) {
      let gruposEnvio = this.gruposAtribuidos;
      let excluidos = this.gruposNaoAtribuidos.filter(
        (x) => x.acao == "excluir"
      );

      gruposEnvio.push(...excluidos);

      this.usuarioService
        .atribuirPermissaoAcesso(
          this.usuarioSelecionado.idUsuario,
          this.centroCustoSelecionado.codigo,
          gruposEnvio
        )
        .subscribe((response) => {
          if (response) {
            this.messageService.success(
              "Atribuição de permissão realizado com sucesso !"
            );
            /*
              this.gruposNaoAtribuidos.forEach(grupo => {
                 grupo.acao = "";
              }, this.gruposNaoAtribuidos);
              this.gruposAtribuidos.forEach(grupo => {
                grupo.acao = "";
             }, this.gruposNaoAtribuidos);
             this.carregarGridGpAtribuidos();
             this.carregarGridGpNaoAtribuidos();
             */
          } else {
            this.messageService.error(response.errors[0]);
          }
        });
    }
  }

  vincularOrgaoUnidade() {
    const modalRef = this.modalService.open(
      VincularOrgaoUnidadeModalComponent,
      {
        size: "lg",
        backdrop: "static",
      }
    );

    modalRef.componentInstance.exibirMenuSuperior = false;

    modalRef.componentInstance.usuarioSelecionadoServico =
      this.usuarioSelecionado;

    var _this = this;
    modalRef.result.then(
      (result) => {
        if (result == "fechado") {
          _this.obterOrgaoUnidadeVinculados(
            _this.usuarioSelecionado.idUsuario.toString()
          );
        } else {
          return;
        }
      },
      (reason) => {}
    );
  }

  selecionarDisponivel(elemento: Grupo, checked) {
    if (elemento.idGrupo != undefined && elemento.idGrupo > 0) {
      if (checked) {
        if (
          !this.gruposNaoAtribuidosSelecionados.find(
            (grupo) => grupo.idGrupo == elemento.idGrupo
          )
        ) {
          if (elemento.idUsuario == undefined || elemento.idUsuario == 0)
            elemento.acao = "incluir";
          else elemento.acao = "";
          this.gruposNaoAtribuidosSelecionados.push(elemento);
        }
      } else {
        let index = this.gruposNaoAtribuidosSelecionados.findIndex(
          (grupo) => grupo.idGrupo == elemento.idGrupo
        );

        this.gruposNaoAtribuidosSelecionados.splice(index, 1);
      }

      if (
        this.gruposAtribuidosSelecionados.length > 0 ||
        this.gruposNaoAtribuidosSelecionados.length > 0
      )
        this.btnVincular = false;
      else this.btnVincular = true;

      console.log(
        "grupos disp selecionados => ",
        this.gruposNaoAtribuidosSelecionados
      );
    }
  }

  selecionarAtribuidos(elemento: Grupo, checked) {
    if (elemento.idGrupo != undefined && elemento.idGrupo > 0) {
      if (checked) {
        if (
          !this.gruposAtribuidosSelecionados.find(
            (grupo) => grupo.idGrupo == elemento.idGrupo
          )
        ) {
          if (elemento.idUsuario > 0) elemento.acao = "excluir";
          this.gruposAtribuidosSelecionados.push(elemento);
        }
      } else {
        let index = this.gruposAtribuidosSelecionados.findIndex(
          (grupo) => grupo.idGrupo == elemento.idGrupo
        );

        this.gruposAtribuidosSelecionados.splice(index, 1);
      }

      if (
        this.gruposAtribuidosSelecionados.length > 0 ||
        this.gruposNaoAtribuidosSelecionados.length > 0
      )
        this.btnVincular = false;
      else this.btnVincular = true;

      console.log(
        "grupos atribuidos selecionados => ",
        this.gruposAtribuidosSelecionados
      );
    }
  }

  incluirSelecionados() {
    if (this.gruposNaoAtribuidosSelecionados.length > 0) {
      this.gruposNaoAtribuidosSelecionados.forEach((grupos) => {
        grupos.selecionado = false;
      }, this.gruposNaoAtribuidosSelecionados);

      //this.gruposAtribuidos = this.gruposNaoAtribuidosAux;
      this.gruposAtribuidos.unshift(...this.gruposNaoAtribuidosSelecionados);
      this.carregarGridGpAtribuidos();

      this.gruposNaoAtribuidosSelecionados.forEach((grupo) => {
        let index = this.gruposNaoAtribuidos.findIndex(
          (gpdisp) => gpdisp.idGrupo == grupo.idGrupo
        );
        this.gruposNaoAtribuidos.splice(index, 1);
      });
      this.carregarGridGpNaoAtribuidos();
      console.log(
        "gruposAssociados disp selecionados => ",
        this.gruposAtribuidos
      );

      this.gruposNaoAtribuidosSelecionados = [];
      this.btnSalvar = false;
      //this.salvar();
    }
  }

  excluirSelecionados() {
    if (this.gruposAtribuidosSelecionados.length > 0) {
      let mensagem = Mensagem.MSE03.replace(
        "{Sistema}",
        this.sistemaSelecionado.sgAplicacao +
          " - " +
          this.sistemaSelecionado.nmAplicacao
      ).replace(
        "{Matricula - Nome}",
        this.usuarioSelecionado.nuMatricula +
          " - " +
          this.usuarioSelecionado.nmUsuario
      );

      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: "450px",
        height: "220px",
        data: { message: mensagem },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.gruposAtribuidosSelecionados.forEach((grupos) => {
            grupos.selecionado = false;
          }, this.gruposAtribuidosSelecionados);

          //this.gruposAssociados = this.gruposDisponiveisAux;
          this.gruposNaoAtribuidos.unshift(
            ...this.gruposAtribuidosSelecionados
          );
          this.carregarGridGpNaoAtribuidos();

          this.gruposAtribuidosSelecionados.forEach((grupo) => {
            let index = this.gruposAtribuidos.findIndex(
              (gpdisp) => gpdisp.idGrupo == grupo.idGrupo
            );
            this.gruposAtribuidos.splice(index, 1);
          });
          this.carregarGridGpAtribuidos();
          console.log(
            "gruposDisponiveis disp selecionados => ",
            this.gruposNaoAtribuidos
          );

          this.gruposAtribuidosSelecionados = [];
          this.btnSalvar = false;
        }
      });
    }
  }

  incluirTodos() {
    if (this.gruposNaoAtribuidos.length > 0) {
      this.gruposNaoAtribuidos.forEach((grupo) => {
        if (grupo.idUsuario == undefined || grupo.idUsuario == 0)
          grupo.acao = "incluir";
        else grupo.acao = "";
      }, this.gruposNaoAtribuidos);

      this.gruposAtribuidos.unshift(...this.gruposNaoAtribuidos);
      console.log("incluir todos => ", this.gruposAtribuidos);
      this.gruposNaoAtribuidos = [];
      this.carregarGridGpNaoAtribuidos();
      this.carregarGridGpAtribuidos();
      this.btnSalvar = false;
    }
  }

  excluirTodos() {
    if (this.gruposAtribuidos.length > 0) {
      let mensagem = Mensagem.MSE02.replace(
        "{Sistema}",
        this.sistemaSelecionado.sgAplicacao +
          " - " +
          this.sistemaSelecionado.nmAplicacao
      );

      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: "450px",
        height: "220px",
        data: { message: mensagem },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.gruposAtribuidos.forEach((grupo) => {
            if (grupo.idUsuario > 0) grupo.acao = "excluir";
            else grupo.acao = "";
          }, this.gruposAtribuidos);

          this.gruposNaoAtribuidos.unshift(...this.gruposAtribuidos);
          this.gruposAtribuidos = [];
          console.log("excluir todos => ", this.gruposNaoAtribuidos);
          this.carregarGridGpAtribuidos();
          this.carregarGridGpNaoAtribuidos();
          this.btnSalvar = false;
          //this.salvar();
        }
      });
    }
  }

  obterGrupoPorOrgaoUnidade(centroCusto: CentroCusto) {
    console.log("orgao unidade => ", centroCusto);

    this.centroCustoSelecionado = centroCusto;

    //let usuario = this.usuarioService.obterUsuarioLogado();
    let usuario = this.usuarioSelecionado;

    this.highlightedRows = [];
    this.highlightedRows.push(centroCusto);

    this.usuarioService
      .obterPerfisUsuario(usuario.nuMatricula, centroCusto.codigo)
      .subscribe((response) => {
        if (response) {
          this.sistemaDisable = false;
          this.sistemas = this.sistemas.filter(
            (a) => a.sgAplicacao != "SICSv2"
          );
          let idsSistemas = this.sistemas.map(function (a) {
            return a.idAplicacao;
          });
          let gruposAplicacao: Array<AplicacaoGrupos> =
            new Array<AplicacaoGrupos>();

          this.vincularGrupoSistemaService
            .obterGruposSistemas(idsSistemas)
            .subscribe((resp) => {
              gruposAplicacao = this.usuarioService.handleResponse(
                resp
              ) as AplicacaoGrupos[];

              this.gruposAtribuidos = [];

              let perfis = this.usuarioService.handleResponse(
                response
              ) as Perfil[];
              perfis = perfis.filter(
                (a) =>
                  gruposAplicacao.findIndex((b) => b.idGrupo == a.codigo) > 0
              );

              if (perfis.length > 0) {
                perfis.forEach((perfil) => {
                  if (
                    this.gruposAtribuidos.findIndex(
                      (x) => x.idGrupo == perfil.codigo
                    ) < 0
                  ) {
                    let grupo = new Grupo();
                    grupo.nmGrupo = perfil.descricao;
                    grupo.idGrupo = perfil.codigo;
                    grupo.idUsuario = this.usuarioSelecionado.idUsuario;
                    this.gruposAtribuidos.unshift(grupo);
                  }
                });
              }

              this.carregarGridGpAtribuidos();
            });
        }
      });
  }
}
