import { BaseService } from "src/app/services/base.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MessageService } from "src/app/services/message.service";
import { Response } from "src/app/shared/models/response";
import { UsuarioAdApi } from "../../models/funcionario-ad-api";
import { Funcionario } from "../../models/funcionario";

@Injectable()
export class UsuarioAdService extends BaseService {
  constructor(
    protected override message: MessageService,
    protected override http: HttpClient
  ) {
    super(message, http);
  }

  consultar(funcionario: Funcionario) {
    return this.http.post<Response>(
      this.apiUrl + UsuarioAdApi.consultar,
      funcionario
    );
  }

  desativarUsuarioAd(formData: FormData) {
    return this.http.post<Response>(
      this.apiUrl + UsuarioAdApi.desativarUsuarioAd,
      formData
    );
  }
}
