export const UsuarioApi = {
    autenticar: 'usuario/autenticar',
    alterarSenha: 'usuario/alterar-senha',
    esqueciSenha: 'usuario/esqueci-senha',
    obterCentrosCusto: 'usuario/obter-centros-de-custo/',
    obterCentrosCustoUsuarioId: 'usuario/obter-centros-de-custo-usuarioId/',
    renovarToken: 'usuario/renovar-token',
    obterPermissoes: 'usuario/obter-permissoes',
    obterUsuarioPorNomeMatricula: 'usuario/obter-usuario-por-nome-matricula/',
    salvarUsuarioSistema: 'usuario/salvar-usuario-sistema',
    excluirUsuarioSistema: 'usuario/excluir-usuario-sistema',
    obterUsuariosAtribuidos: 'usuario/obter-usuarios-atribuidos/',
    adicionarCentroDeCusto: 'usuario/adicionar-centro-custo/',
    removerCentroDeCusto: 'usuario/remover-centro-custo/',
    obterPerfisUsuario: 'usuario/obter-perfis-usuario/',
    atribuirPermissaoAcesso: 'usuario/atribuir-permissao-acesso/'
}
