import { UsuarioDesativacaoFiltro } from '../models/usuario-desativacao-filtro';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageService } from 'src/app/services/message.service';
import { UsuarioDesativacaoService } from './Services/usuario-desativacao.service';
import { Mensagem } from 'src/app/shared/models/mensagem';
import { UsuarioDesativacao } from '../models/usuario-desativacao';

@Component({
  selector: 'app-relatorio-usuarios-ad',
  templateUrl: './relatorio-usuarios-ad.component.html',
  styleUrls: ['./relatorio-usuarios-ad.component.scss']
})
export class RelatorioUsuariosAdComponent implements OnInit {

  usuariosDesativacao = new Array<UsuarioDesativacao>();
  encontradoRegistro = true;
  mensagemNenhumRegistro = Mensagem.nenhumRegistroEncontrado;
  minDate = new Date('2000-01-01');
  maxDate = new Date('2099-12-31');

  formulario = new FormGroup({
    nome: new FormControl(),
    matricula: new FormControl(),
    login: new FormControl(),
    cargo: new FormControl(),
    usuarioDesativacaoNome: new FormControl(),
    dataDesativacaoInicial: new FormControl(),
    dataDesativacaoFinal: new FormControl(),
    dje: new FormControl(),
    dataPublicacaoInicialDje: new FormControl(),
    dataPublicacaoFinalDje: new FormControl()
  });

  // grid
  displayedColumns = ['dataDesativacao', 'nomeResponsavel', 'nomeUsuario', 'login', 'matricula', 'numeroDje', 'numeroAto', 'anexos'];
  dataSource = new MatTableDataSource<UsuarioDesativacao>(this.usuariosDesativacao);
  pageSize = 20;
  pageSizeOptions = [this.pageSize];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private formBuilder: FormBuilder,
    private usuarioDesativacaoService: UsuarioDesativacaoService,
    private messageService: MessageService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  limparClick() {
    this.formulario.controls.nome.setValue(null);
    this.formulario.controls.matricula.setValue(null);
    this.formulario.controls.login.setValue(null);
    this.formulario.controls.cargo.setValue(null);
    this.formulario.controls.usuarioDesativacaoNome.setValue(null);
    this.formulario.controls.dataDesativacaoInicial.setValue(null);
    this.formulario.controls.dataDesativacaoFinal.setValue(null);
    this.formulario.controls.dje.setValue(null);
    this.formulario.controls.dataPublicacaoInicialDje.setValue(null);
    this.formulario.controls.dataPublicacaoFinalDje.setValue(null);
    this.usuariosDesativacao = new Array<UsuarioDesativacao>();
    this.dataSource = new MatTableDataSource<UsuarioDesativacao>(this.usuariosDesativacao);
    this.encontradoRegistro = true;
  }

  baixarRelatorioXlsxClick() {
    let filtro = this.obterFiltro();

    this.usuarioDesativacaoService.baixarRelatorioXlsx(filtro)
      .subscribe(
        // FAZ O DOWNLOAD
      );
  }

  baixarRelatorioPdfClick() {
    let filtro = this.obterFiltro();

    this.usuarioDesativacaoService.baixarRelatorioPdf(filtro)
      .subscribe(
        // FAZ O DOWNLOAD
      );
  }

  validaCampos(filtro: UsuarioDesativacaoFiltro) {
    if (filtro.dataDesativacaoInicial && filtro.dataDesativacaoFinal) {
      if (filtro.dataDesativacaoInicial > filtro.dataDesativacaoFinal) {
        this.messageService.error(Mensagem.intervaloDataInvalido);
        return false;
      }
    }

    return true;
  }

  pesquisarClick() {

    let filtro = this.obterFiltro();

    if (this.validaCampos(filtro)) {

      this.usuarioDesativacaoService.consultar(filtro).subscribe(
        response => {
          this.usuariosDesativacao = this.usuarioDesativacaoService.handleResponse(response) as UsuarioDesativacao[];
          if (this.usuariosDesativacao) {
            this.encontradoRegistro = this.usuariosDesativacao.length > 0;
            this.dataSource = new MatTableDataSource<UsuarioDesativacao>(this.usuariosDesativacao);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
          else {
            this.messageService.error("Erro na pesquisa, por favor comunique o analista");
          }
        }
      );
    }
  }

  obterFiltro() {
    let filtro = new UsuarioDesativacaoFiltro();

    filtro.nome = this.formulario.controls.nome.value?.trim() || undefined;
    filtro.matricula = parseInt(this.formulario.controls.matricula.value, 10) || undefined;
    filtro.login = this.formulario.controls.login.value?.trim() || undefined;
    filtro.cargo = this.formulario.controls.cargo.value?.trim() || undefined;
    filtro.nomeUsuarioDesativacao = this.formulario.controls.usuarioDesativacaoNome.value?.trim() || undefined;
    filtro.dataDesativacaoInicial = this.formulario.controls.dataDesativacaoInicial.value || undefined;
    filtro.dataDesativacaoFinal = this.formulario.controls.dataDesativacaoFinal.value || undefined;
    filtro.numeroDje = this.formulario.controls.dje.value || undefined;
    filtro.dataPublicacaoInicial = this.formulario.controls.dataPublicacaoInicialDje.value || undefined;
    filtro.dataPublicacaoFinal = this.formulario.controls.dataPublicacaoFinalDje.value || undefined;

    return filtro;
  }

  baixarAnexos(anexosIds: number[]) {
    if (anexosIds?.length) {
      anexosIds.forEach(id => {
        this.baixarAnexo(id);
      });
    }
  }

  baixarAnexo(idAnexo: number) {
    this.usuarioDesativacaoService.baixarAnexo(idAnexo)
      .subscribe(
        // FAZ O DOWNLOAD
      );
  }
}
