export class Funcionario {
    idUsuario: number;
    nuMatricula: number;
    nmLogin: string;
    nmFuncionario: string;
    dsCargoComissionado: string;
    dsCargo: string;
    stMembro: boolean;
    stSICSAtivoAD: boolean;
    stSICSAtivo: boolean;
    idSICS: number;
    dsSetor: string;

    //nmCargo: string;
    // dtNascimento: string;
    // nuCPF: number;
    // nuPisPasep: number;
    // stSexo: string;
    // dsEndereco: string;
    // dsBairro: string;
    // cdMunicipio: number;
    // nmMunicipio: string;
    // nuCEP: number;
    // nuDDD: number;
    // nuFone: string;
    // nuCelular: string;
    // dsEmail: string;
    // dtAdmissao: string;
    // cdCargo: number;
    // tpFuncao: string;
    // cdSituacao: number;
    // dsSituacao: string;
    // idLotacao: number;   
    // idCentroCusto: number;
    // nuRegSICS: number;    
    // nuRegAD: number;
    // stFuncao: string;
    // cdBanco: number;
    // cdAgencia: string;
    // cdConta: string;
    // dsClasse: string;
    // dsSimbolo: string;
    // cdCargoComissionado: number;
    // nuIdentidade: string;
    // dsOrgaoIdentidade: string;
    // dsUFIdentidade: string;
    // dtIdentidade: string;
    // nuTitEleitor: number;
    // nuSecTitEleitor: number;
    // nuZonaTitEleitor: number;
    // dtTitEleitor: string;
    // sgUFTitEleitor: string;
    // cdEstadoCivil: number;
    // cdGrauInstrucao: number;
    // nmMae: string;
    // nmPai: string;
    // dtObito: string;
    // pageIndex: number;
}