import { Component, OnInit, ViewChild } from '@angular/core';
import { UsuarioService } from '../services/usuario.service';
import { CentroCusto } from '../models/centro-custo';
import { Claim } from '../models/claim';
import { Usuario } from '../models/usuario';
import { Sistema } from 'src/app/services/models/constants';
import { Token } from '../models/token';
import { Router } from '@angular/router';
import { Pages } from 'src/app/shared/models/pages';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Mensagem } from 'src/app/shared/models/mensagem';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'app-centro-custo',
  templateUrl: './centro-custo.component.html',
  styleUrls: ['./centro-custo.component.scss']
})
export class CentroCustoComponent implements OnInit {

  public centros: Array<CentroCusto> = new Array<CentroCusto>();
  private usuario: Usuario;

  pageSize = 10;
  pageSizeOptions: number[] = [this.pageSize];
  displayedColumns = ['nome'];
  dataSource = new MatTableDataSource<CentroCusto>([]);

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private usuarioService: UsuarioService, private router: Router, private messageService: MessageService,) { }

  ngOnInit() {
    this.usuario = this.usuarioService.obterUsuarioLogado();
    if (this.usuario && this.usuario.autenticado)
      this.obterCentrosCusto(this.usuario.usuarioSics.toString());
  }

  obterCentrosCusto(usuarioSics: string) {
    this.usuarioService.obterCentrosCustoUsuarioId(usuarioSics)
      .subscribe(
        response => {
          this.centros = this.usuarioService.handleResponse(response) as Array<CentroCusto>;
          if (this.centros) {
            this.dataSource = new MatTableDataSource<CentroCusto>(this.centros);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
          }
          else {
            this.messageService.error(Mensagem.erroAoPesquisar);
          }
        });
  }

  obterPermissoes(centroCusto: CentroCusto) {
    localStorage.setItem(Sistema.localStorage.centroCusto, JSON.stringify(centroCusto));

    let token = new Token();
    token.login = this.usuario.login;
    token.usuarioSics = this.usuario.usuarioSics;
    token.centroCustoId = centroCusto.codigo;

    this.usuarioService.obterPermissoes(token)
      .subscribe(response => { this.atribuirPermissoes(this.usuarioService.handleResponse(response)); });
  }

  atribuirPermissoes(response: any) {
    if (response && response.autenticado) {
      let claims = new Array<Claim>();

      response.claims.forEach((x: { tipo: string; valor: string; }) => {
        var tmptipo = x.tipo;
        if(x.tipo.includes("<br>"))
        { 
          var re = /<br>/gi;      
          tmptipo = x.tipo.replace(re,'');        
        }
        claims.push(new Claim(tmptipo, x.valor));
      })

      this.usuario.autenticado = response.autenticado;
      this.usuario.token = response.token;
      this.usuario.claims = claims;
      localStorage.setItem(Sistema.localStorage.user, JSON.stringify(this.usuario));
      localStorage.setItem(Sistema.localStorage.token, JSON.stringify(this.usuario.token));
      this.router.navigate([Pages.Principal.home]);
    }
  }

}
