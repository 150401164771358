import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { UsuarioAdService } from './Services/usuario-ad.service';
import { MessageService } from 'src/app/services/message.service';
import { Router } from '@angular/router';
import { Funcionario } from '../models/funcionario';
import { Anexo } from '../models/anexo';
import { MatTableDataSource } from '@angular/material/table';
import { Mensagem } from 'src/app/shared/models/mensagem';

@Component({
  selector: 'app-desativar-usuarios-ad',
  templateUrl: './desativar-usuarios-ad.component.html',
  styleUrls: ['./desativar-usuarios-ad.component.scss']
})
export class DesativarUsuariosAdComponent implements OnInit {

  funcionario = new Funcionario();
  listaAnexos = new Array<Anexo>();
  dataSource = new MatTableDataSource(this.listaAnexos);
  displayedColumns = ["nomeArquivo", "acao"];
  //acceptFiles = ['.jpg', '.jpeg', '.gif', '.png', '.bmp', '.pdf', '.odt', '.doc', '.docx', '.zip', '.rar'];
  acceptFiles = ['.jpg', '.jpeg', '.png', '.pdf'];
  tamanhoMaxAnexo = 100; // MB

  @ViewChild('upload') seletorAnexo: ElementRef;

  constructor(private formBuilder: FormBuilder,
    private usuarioAdService: UsuarioAdService,
    private messageService: MessageService,
    private router: Router
  ) { }

  funcionarioForm = new FormGroup({
    nomeCompleto: new FormControl(),
    login: new FormControl(),
    matricula: new FormControl(),
    lotacao: new FormControl(),
    cargoComissionado: new FormControl(),
    cargoEfetivo: new FormControl()
  });

  usuarioDesativacaoForm = new FormGroup({
    nmLogin: new FormControl(),
    nuDje: new FormControl(),
    dtPublicacao: new FormControl(),
    nuAto: new FormControl,
    exoneradoDje: new FormControl(),
    cienteAcao: new FormControl
  });

  ngOnInit() {
    let funcionarioJSON = window.localStorage.getItem('funcionario');
    let funcionario = JSON.parse(funcionarioJSON);
    this.preencherTela(funcionario)
    this.alternarCamposExoneracao();
  }

  preencherTela(funcionario: Funcionario) {
    this.funcionarioForm.controls.nomeCompleto.setValue(funcionario.nmFuncionario);
    this.funcionarioForm.controls.nomeCompleto.disable();

    this.funcionarioForm.controls.login.setValue(funcionario.nmLogin);
    this.funcionarioForm.controls.login.disable();

    this.funcionarioForm.controls.matricula.setValue(funcionario.nuMatricula);
    this.funcionarioForm.controls.matricula.disable();

    this.funcionarioForm.controls.lotacao.setValue(funcionario.dsSetor);
    this.funcionarioForm.controls.lotacao.disable();

    this.funcionarioForm.controls.cargoComissionado.setValue(funcionario.dsCargoComissionado);
    this.funcionarioForm.controls.cargoComissionado.disable();

    this.funcionarioForm.controls.cargoEfetivo.setValue(funcionario.dsCargo);
    this.funcionarioForm.controls.cargoEfetivo.disable();

    this.usuarioDesativacaoForm.controls.exoneradoDje.setValue(0);
    this.usuarioDesativacaoForm.controls.nmLogin.setValue(funcionario.nmLogin);
  }

  camposValidos() {

    var camposValidos = true;

    if (this.usuarioDesativacaoForm.controls.exoneradoDje.value == null) {
      camposValidos = false;
    }

    if (this.usuarioDesativacaoForm.controls.exoneradoDje.value == 1) {
      if (this.usuarioDesativacaoForm.controls.nuDje.value == null ||
        this.usuarioDesativacaoForm.controls.dtPublicacao.value == null ||
        (this.usuarioDesativacaoForm.controls.nuAto.value == null || this.usuarioDesativacaoForm.controls.nuAto.value == "")) {
          camposValidos = false;
      }
    }

    if (camposValidos) {
      if (this.usuarioDesativacaoForm.controls.cienteAcao.value != true) {
        this.messageService.error(Mensagem.marcarCiencia);
        return false;
      }
      else {
        return true;
      }
    }
    else {
      this.messageService.error(Mensagem.verifiqueCampos);
      return false;
    }
  }

  alternarCamposExoneracao() {
    if (this.usuarioDesativacaoForm.controls.exoneradoDje.value == 1) {
      this.usuarioDesativacaoForm.controls.nuDje.enable();
      this.usuarioDesativacaoForm.controls.dtPublicacao.enable();
      this.usuarioDesativacaoForm.controls.nuAto.enable();
    }
    else {
      this.usuarioDesativacaoForm.controls.nuDje.disable();
      this.usuarioDesativacaoForm.controls.nuDje.setValue(null);

      this.usuarioDesativacaoForm.controls.dtPublicacao.disable();
      this.usuarioDesativacaoForm.controls.dtPublicacao.setValue(null);

      this.usuarioDesativacaoForm.controls.nuAto.disable();
      this.usuarioDesativacaoForm.controls.nuAto.setValue(null);
    }
  }

  desativarUsuarioAd() {

    if (this.camposValidos()) {

      const formData = new FormData();
      const formRawValue = this.usuarioDesativacaoForm.getRawValue();
      console.log("login value  => ", this.usuarioDesativacaoForm.controls.nmLogin.value);
      console.log("dados => ", formRawValue);
      const json = JSON.stringify(formRawValue, (key, value) => {
        if (value != null)
          return value
      });

      if(this.usuarioDesativacaoForm.controls.nmLogin.value == null || this.usuarioDesativacaoForm.controls.nmLogin.value == "")
      {
        this.messageService.error("Não foi fornecido o login do usuário " + this.funcionarioForm.controls.nomeCompleto.value);
        return false;
      }

      formData.append('form', json);

      this.listaAnexos.forEach((anexo, index) => {
        const nomeDado = `files[${index}]`;
        formData.append(nomeDado, anexo.arquivo, anexo.nomeArquivo);
      });

      this.usuarioAdService.desativarUsuarioAd(formData).subscribe(
        response => {
          if (response.success) {
            this.messageService.success(Mensagem.usuarioDesativado);
          }
          else {
            this.messageService.error(response.errors[0]);
          }
        }
      );
    }
  }

  arquivoValido(arquivo: File): Boolean {
    const ext = arquivo.name.split('.').pop().toLowerCase();

    if (!this.acceptFiles.some(x => x === '.' + ext)) {
      this.messageService.error(Mensagem.arquivoNaoPermitido);
      return false;
    }

    if (((arquivo.size / 1024) / 1024) > this.tamanhoMaxAnexo) {
      this.messageService.error(Mensagem.tamanhoMaxAnexo + `${this.tamanhoMaxAnexo}MB.`);
      return false;
    }

    return true;
  }

  adicionarAnexo(arquivos: File[]) {

    for (var i = 0; i < arquivos.length; i++) {
      const arquivo = arquivos[i];

      if (this.arquivoValido(arquivo)) {
        const anexo = new Anexo();
        anexo.arquivo = arquivo;

        this.listaAnexos.unshift(anexo);

        this.dataSource = new MatTableDataSource(this.listaAnexos);
      }
    };

    this.seletorAnexo.nativeElement.value = '';
  }

  excluirAnexo(anexo: Anexo) {
    var index = this.listaAnexos.indexOf(anexo);
    if (index !== -1) {
      this.listaAnexos.splice(index, 1);
    }

    this.dataSource = new MatTableDataSource(this.listaAnexos);
  }
}