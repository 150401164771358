import { BaseService } from "src/app/services/base.service";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MessageService } from "src/app/services/message.service";
import { Response } from "src/app/shared/models/response";
import { UsuarioDesativacaoApi } from "../../consultar-usuarios-ad/models/usuario-desativacao-api";
import { UsuarioDesativacaoFiltro } from "../../models/usuario-desativacao-filtro";
import { ToastrService } from "ngx-toastr";
import { catchError, tap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { saveAs } from "file-saver";
import { Mensagem } from "src/app/shared/models/mensagem";

@Injectable()
export class UsuarioDesativacaoService extends BaseService {
  constructor(
    protected override message: MessageService,
    protected override http: HttpClient,
    private toastr: ToastrService
  ) {
    super(message, http);
  }

  consultar(usuarioDesativacaoFiltro: UsuarioDesativacaoFiltro) {
    return this.http.post<Response>(
      this.apiUrl + UsuarioDesativacaoApi.consultar,
      usuarioDesativacaoFiltro
    );
  }

  obterIdsAnexos(idUsuarioDesativacao: number) {
    return this.http.get(
      this.apiUrl + UsuarioDesativacaoApi.obterIdsAnexos + idUsuarioDesativacao
    );
  }

  baixarRelatorioXlsx(usuarioDesativacaoFiltro: UsuarioDesativacaoFiltro) {
    return this.baixarRelatorio(
      usuarioDesativacaoFiltro,
      this.apiUrl + UsuarioDesativacaoApi.baixarRelatorioXlsx,
      "relatorio",
      Mensagem.erroBaixarRelatorio
    );
  }

  baixarRelatorioPdf(usuarioDesativacaoFiltro: UsuarioDesativacaoFiltro) {
    return this.baixarRelatorio(
      usuarioDesativacaoFiltro,
      this.apiUrl + UsuarioDesativacaoApi.baixarRelatorioPdf,
      "relatorio",
      Mensagem.erroBaixarRelatorio
    );
  }

  baixarAnexo(idAnexo: number) {
    var observavel = this.http.get(
      this.apiUrl + UsuarioDesativacaoApi.baixarAnexo + idAnexo,
      { observe: "response", responseType: "blob" }
    );
    return this.salvarArquivo(observavel, "anexo", Mensagem.erroBaixarAnexo);
  }

  private baixarRelatorio(
    usuarioDesativacaoFiltro: UsuarioDesativacaoFiltro,
    url: string,
    nomeGenerico: string,
    mensagemErro: string
  ) {
    var observavel = this.http.post(url, usuarioDesativacaoFiltro, {
      observe: "response",
      responseType: "blob",
    });
    return this.salvarArquivo(observavel, nomeGenerico, mensagemErro);
  }

  private salvarArquivo(
    observavel: Observable<any>,
    nomeGenerico: string,
    mensagemErro: string
  ) {
    return observavel.pipe(
      tap((response) => {
        const contentDisposition =
          response.headers.get("content-disposition") || "";
        //const regex = /filename="?([^;]+)"?/ig;
        const regex = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi;
        const matches = regex.exec(contentDisposition);
        const fileName = ((matches && matches[1]) || nomeGenerico)
          .trim()
          .replace(/[+]/g, " ");
        saveAs(response.body, fileName);
      }),
      catchError((error) => {
        this.toastr.error(mensagemErro);
        return of(error);
      })
    );
  }
}
