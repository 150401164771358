export class Pages {
    public static Usuario = {
        login: '/login',
        centroCusto: '/centro-de-custo',
        esqueciSenha: '/esqueci-senha'
    }

    public static Principal = {
        home: '/home'
    }

    public static TipoEntidade = {
      consultar: '/tipo-entidade/consultar/',
      cadastrar: '/tipo-entidade/adicionar/'
    }

    public static ModeloOficio = {
      consultar: '/modelo-oficio/consultar/',
      cadastrar: '/modelo-oficio/adicionar/'
    }

    public static UsuarioExterno = {
      consultar: 'usuario-externo/consultar',
      adicionar: 'usuario-externo/adicionar',
      editar: 'usuario-externo/editar',
      visualizar: 'usuario-externo/visualizar'
    }

    public static Secao = {
      consultar: '/secao/consultar/',
      cadastrar: '/secao/adicionar/'
    }

    public static Pergunta = {
      consultar: '/pergunta/consultar/',
      cadastrar: '/pergunta/adicionar/',
      editarCodigo: '/pergunta/editardscodigo/',
      visualizarCodigo: 'pergunta/visualizarCodigo/'
    }

    public static Formulario = {
      consultar: '/formulario/consultar/',
      cadastrar: '/formulario/adicionar/'
    }

    public static GerarFormularioVisita = {
      gerarFormulario: '/gerar-formulario/'   
    }

    public static ConsultarUsuariosAd = {
      consultarUsuariosAd: 'consultar-usuarios-ad/'
    }

    public static DesativarUsuariosAd = {      
      desativarUsuariosAd: 'desativar-usuarios-ad/'
    }
}
