import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Observable } from 'rxjs';
import { MessageService } from 'src/app/services/message.service';
import { distinctUntilChanged, tap, switchMap, finalize } from 'rxjs/operators';
import { ConfirmComponent } from 'src/app/shared/confirm/confirm.component';
import { Mensagem } from 'src/app/shared/models/mensagem';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Usuario } from 'src/app/SICSv2/usuario/models/usuario';
import { OrgaoUnidade } from 'src/app/SICSv2/models/orgaoUnidade';
import { CentroCusto } from 'src/app/SICSv2/usuario/models/centro-custo';
import { UsuarioService } from 'src/app/SICSv2/usuario/services/usuario.service';
import { OrgaoUnidadeService } from 'src/app/SICSv2/vincular-orgao-unidade-usuario/services/orgao-unidade.service';

@Component({
  selector: 'app-vincular-orgao-unidade-modal',
  templateUrl: './vincular-orgao-unidade-modal.component.html',
  styleUrls: ['./vincular-orgao-unidade-modal.component.scss']
})
export class VincularOrgaoUnidadeModalComponent implements OnInit {

  @Input() exibirMenuSuperior = true as boolean;

  @Input() usuarioSelecionadoServico = {} as Usuario;

  consultarDadosForm = new FormGroup({
		nmUsuario: new FormControl(),
		descOrgao: new FormControl()
	});

  btnSalvar: boolean = true;
  btnVincularOrgao: boolean = true;
  orgaoUnidadeCarregando: boolean = false;
  usuarioCarregando: boolean = false;
  descricaoUsuario: string = "";
  dadosUsuario: boolean = false;

  tipoUsuario: string = "";
  loginUsuario: string = "";
  orgaoOrigemUsuario: string = "";

  usuarioSelecionado: Usuario = new Usuario();
  orgaoUnidadeSelecionado: OrgaoUnidade = new OrgaoUnidade();
  

  @ViewChild('paginatorVinculado') paginatorVinculado: MatPaginator;
  @ViewChild('paginatorNaoVinculado') paginatorNaoVinculado: MatPaginator;  
  @ViewChild(MatSort) sort: MatSort;

  orgaoUnidadeVinculados: Array<CentroCusto> = new Array<CentroCusto>();
  orgaoUnidadeNaoVinculados: Array<OrgaoUnidade> = new Array<OrgaoUnidade>();

  orgaoUnidadeObservable: Observable<any[]>;  
  usuariosObservable: Observable<any[]>;

  public centros: Array<CentroCusto> = new Array<CentroCusto>();

  displayedColumns = ['nmOrgao','stTipo','acoes'];

  dataSourceOrgaoUndidadesVinculados = new MatTableDataSource<CentroCusto>(this.orgaoUnidadeVinculados);
  dataSourceOrgaoUndidadesNaoVinculados = new MatTableDataSource<OrgaoUnidade>(this.orgaoUnidadeNaoVinculados);
  
  pageSize = 10;
  pageSizeOptions = [this.pageSize];

  lengthOrgaoUnidadeVinculado = this.orgaoUnidadeVinculados.length;
  lengthOrgaoUnidadeNaoVinculado = this.orgaoUnidadeNaoVinculados.length;

  constructor(private messageService: MessageService,
    private usuarioService: UsuarioService,
    private orgaoUnidadeService: OrgaoUnidadeService,
    public activeModal: NgbActiveModal,
    public dialog: MatDialog) { }

    ngOnInit() 
    {
      this.usuarioIniAutoComplete();
      this.orgaoUnidadeAutoComplete();
      this.controls.descOrgao.disable();
  
      if(this.usuarioSelecionadoServico != null && this.usuarioSelecionadoServico.idUsuario > 0)
      {
          this.selecionarUsuario(this.usuarioSelecionadoServico);
      }
    }
  
    obterOrgaoUnidadeVinculados(usuarioSics: string) {
      this.usuarioService.obterCentrosCustoUsuarioId(usuarioSics)
        .subscribe(
          response => {
            this.orgaoUnidadeVinculados = this.usuarioService.handleResponse(response) as Array<CentroCusto>;
            if (this.orgaoUnidadeVinculados) {
              this.preencheGridOrgaoUndidadesVinculados();
            }         
          });
    }
  
    preencheGridOrgaoUndidadesVinculados()
    {
      this.dataSourceOrgaoUndidadesVinculados = new MatTableDataSource<CentroCusto>(this.orgaoUnidadeVinculados);
      this.dataSourceOrgaoUndidadesVinculados.sort = this.sort;
      this.dataSourceOrgaoUndidadesVinculados.paginator = this.paginatorVinculado;
    }
  
    visualizarUsuarioSelecionado(usuario: Usuario) 
    {
      if (usuario && usuario.idUsuario != 0)
        return usuario.nmUsuario + " - " + usuario.nmLogin;
      else
        return '';
    }
  
    selecionarUsuario(usuario: Usuario)
    {
      console.log("usuario selecionado => ", usuario);
      this.usuarioSelecionado = new Usuario();
  
      this.usuarioSelecionado = usuario;
      this.dadosUsuario = false;
      this.btnSalvar = true;
  
      if(usuario.idUsuario > 0){
        this.dadosUsuario = true;
        //this.tipoUsuario = usuario.
        this.loginUsuario = usuario.nmLogin;
        this.orgaoOrigemUsuario = usuario.centroCusto;
  
        this.controls.descOrgao.enable();
        this.btnSalvar = false;
  
        this.obterOrgaoUnidadeVinculados(usuario.idUsuario.toString());
  
      }
  
    }
  
    selecionarOrgaUnidade(orgaoUnidade: OrgaoUnidade)
    {
      this.orgaoUnidadeSelecionado = new OrgaoUnidade();
  
      if(orgaoUnidade.idOrgaoUnidade > 0)
      {
        this.orgaoUnidadeSelecionado = orgaoUnidade;
        this.btnVincularOrgao = false;
      }
    }
  
    visualizarOrgaoUnidadeSelecionado(orgaoUnidade: OrgaoUnidade)
    {
      if (orgaoUnidade && orgaoUnidade.idOrgaoUnidade != 0)
        return orgaoUnidade.descricao;
      else
        return ''; 
    }
  
    private carregarGridOrgaoUnidadeNaoVinculado()
    {
      this.dataSourceOrgaoUndidadesNaoVinculados = new MatTableDataSource<OrgaoUnidade>(this.orgaoUnidadeNaoVinculados);
      this.dataSourceOrgaoUndidadesNaoVinculados.paginator = this.paginatorNaoVinculado;
    
      this.dataSourceOrgaoUndidadesNaoVinculados.sortingDataAccessor = (item, property) => {
            switch (property) {
          case 'nmGrupo':
            return item.descricao.toUpperCase();						        
          default:
              return item[property];
            }
      };	
    
      this.dataSourceOrgaoUndidadesNaoVinculados.sort = this.sort; 
    }
  
    salvar()
    {
      if(this.usuarioSelecionado.idUsuario > 0 && this.orgaoUnidadeNaoVinculados.length > 0)
      {
        var idUsuario = this.usuarioSelecionado.idUsuario;
        var obs = new Array<Observable<any>>();
  
        this.orgaoUnidadeNaoVinculados.forEach(orgaUnidade => {
          obs.push(this.usuarioService.adicionarCentroDeCusto(idUsuario, orgaUnidade.idOrgaoUnidade));
        });
  
        var erro = false;
        forkJoin(obs).subscribe(responses => {
           responses.forEach(response => {
              if(!response)
                erro = true;
           });
  
           this.obterOrgaoUnidadeVinculados(this.usuarioSelecionado.idUsuario.toString());
  
           if(erro)
           {
              this.messageService.error("Ocorreu um erro ao tentar relacionar os órgão unidades ao usuário !");
           }else
           {
              this.orgaoUnidadeNaoVinculados = new Array<OrgaoUnidade>();
              this.carregarGridOrgaoUnidadeNaoVinculado();
              this.messageService.success("Órgão(s) unidade(s) relacionados com sucesso !");
           }
        });
  
        
  
      }else
      {
        if(this.usuarioSelecionado.idUsuario <= 0){
          this.messageService.warning("Favor informar o campo Usuário");
          return;
        }
  
        if(this.orgaoUnidadeNaoVinculados.length <= 0)
          this.messageService.warning("Favor vincular órgão(s) unidade(s) ao Usuário");
  
      }
    }
  
    removerOrgaoUsuario(centroCusto: CentroCusto)
    {
  
      let mensagem = Mensagem.MSE04.replace('{Descricao}', centroCusto.nome)
                                   .replace('{Nome do usuario - Matricula}',this.usuarioSelecionado.nmUsuario +" - " + this.usuarioSelecionado.nuMatricula);
      
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '450px',
        height: '220px',
        data: { message: mensagem }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result)
        {
          let index = this.orgaoUnidadeVinculados.findIndex(x => x.codigo == centroCusto.codigo);
      
          if(index >= 0)
          { 
            var orgaoUnidade = this.orgaoUnidadeVinculados.find(x => x.codigo == centroCusto.codigo);
            this.usuarioService.removerCentroDeCusto(this.usuarioSelecionado.idUsuario, orgaoUnidade.codigo).subscribe(
              response => {
                if(response)
                {
                    this.obterOrgaoUnidadeVinculados(this.usuarioSelecionado.idUsuario.toString());  
                    this.messageService.success("Orgão Unidade removido com sucesso !");
                }else
                {
                    this.messageService.error(response.errors[0]);
                } 
            }
            );          
          }
        }
      });
  
        
    }
  
    excluirOrgao(orgaoUnidade: OrgaoUnidade)
    {
      
      let mensagem = Mensagem.MSE04.replace('{Descricao}', orgaoUnidade.descricao)
                                   .replace('{Nome do usuario - Matricula}',this.usuarioSelecionado.nmUsuario +" - " + this.usuarioSelecionado.nuMatricula);
      
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '450px',
        height: '220px',
        data: { message: mensagem }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if(result)
        {
          let index = this.orgaoUnidadeNaoVinculados.findIndex(x => x.idOrgaoUnidade == orgaoUnidade.idOrgaoUnidade);
      
          if(index >= 0)
          { 
            this.orgaoUnidadeNaoVinculados.splice(index, 1);
            this.carregarGridOrgaoUnidadeNaoVinculado();
          }
        }
      });
  
    }
  
    vincularOrgao()
    {
      let orgaoUnidade = this.orgaoUnidadeSelecionado;
  
      if(orgaoUnidade.idOrgaoUnidade > 0 && this.orgaoUnidadeNaoVinculados.findIndex(x => x.idOrgaoUnidade == orgaoUnidade.idOrgaoUnidade) < 0)
        {
          this.orgaoUnidadeNaoVinculados.unshift(orgaoUnidade);
          this.controls.descOrgao.patchValue("");
          this.carregarGridOrgaoUnidadeNaoVinculado();
          this.btnVincularOrgao = true;
        }
    }
  
    get controls() 
    {
      return this.consultarDadosForm.controls;
    }
  
    usuarioIniAutoComplete()
    {
      this.usuariosObservable = this.controls.nmUsuario.valueChanges
        .pipe(			
        distinctUntilChanged(),
        tap(() => {
          this.usuarioCarregando = true;			  
        }),
        switchMap(value => {
          return this.usuarioService.obterUsuarioPorNomeMatricula(value || '')
          .pipe(finalize(() => { this.usuarioCarregando = false; }))
        })
      );
    }
  
    orgaoUnidadeAutoComplete()
    {
        this.orgaoUnidadeObservable = this.controls.descOrgao.valueChanges
        .pipe(			
          distinctUntilChanged(),
          tap(() => {
            this.orgaoUnidadeCarregando = true;			  
          }),
          switchMap(value => {
            return this.orgaoUnidadeService.obterEntidadePorDescricao(value || '')
            .pipe(finalize(() => { this.orgaoUnidadeCarregando = false; }))
          })
        );
    }
  
    fecharModal()
    {
      this.activeModal.close('fechado');
    }

}
