export class CentroCusto {
    codigo: number;
    nome: string;
    nomeMunicipio: string;
    //tipoAtuacao: number;
    stOrigemSics: boolean;
    descricaoTipoAtuacao: string | null;;
    //dataInicioAtuacao: string | null;
    //dataFimAtuacao: string | null;
}
