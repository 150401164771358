// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-header {
  border-bottom: 0px !important;
  background-color: #b4d6e5;
}

.esqueci-senha {
  padding-top: 7px;
  text-align: right;
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/SICSv2/usuario/login/login.component.scss"],"names":[],"mappings":"AAAA;EACE,6BAAA;EACA,yBAAA;AACF;;AAEA;EACE,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE;;;;;IAKE,iBAAA;EACF;AACF;AACA;EACE;;;;IAIE,gBAAA;EACF;AACF;AACA;EACE;;;IAGE,gBAAA;EACF;AACF;AACA;EACE;;IAEE,gBAAA;EACF;AACF","sourcesContent":[".card-header {\r\n  border-bottom: 0px !important;\r\n  background-color: #b4d6e5;\r\n}\r\n\r\n.esqueci-senha {\r\n  padding-top: 7px;\r\n  text-align: right;\r\n}\r\n\r\n@media (min-width: 1200px) {\r\n  .container,\r\n  .container-lg,\r\n  .container-md,\r\n  .container-sm,\r\n  .container-xl {\r\n    max-width: 1140px;\r\n  }\r\n}\r\n@media (min-width: 992px) {\r\n  .container,\r\n  .container-lg,\r\n  .container-md,\r\n  .container-sm {\r\n    max-width: 960px;\r\n  }\r\n}\r\n@media (min-width: 768px) {\r\n  .container,\r\n  .container-md,\r\n  .container-sm {\r\n    max-width: 720px;\r\n  }\r\n}\r\n@media (min-width: 576px) {\r\n  .container,\r\n  .container-sm {\r\n    max-width: 540px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
