import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-show-errors',
  template: `
    <div *ngIf="shouldShowErrors()" class="alert alert-danger error-padding">
      <span *ngFor="let error of listOfErrors()">{{error}}</span>
    </div>
  `,
})
export class ShowErrorsComponent {

  private static readonly errorMessages = {
    'required': () => 'Campo Obrigatório. ',
    'noWhitespaceValidator': (params) => 'Campo Obrigatório. ',
    'minlength': (params) => 'A quantidade mínima de caracteres é ' + params.requiredLength + '. ' ,
    'maxlength': (params) => 'A quantidade máxima de caracteres é ' + params.requiredLength + '. ',
    'min': (params) => 'O valor mínimo do campo é ' + params.min + ' ',
    'max': (params) => 'O valor máximo do campo é ' + params.max + ' ',
    'pattern': (params) => 'A regra para este campo é: ' + params.requiredPattern,
    'years': (params) => params.message,
    'countryCity': (params) => params.message,
    'uniqueName': (params) => params.message,
    'telephoneNumbers': (params) => params.message,
    'telephoneNumber': (params) => params.message,
    'email': () => 'O e-mail informado é inválido.',
    'dateInvalid': (params) => params.message,
    'cpfInvalid': (params) => params.message,
    'senhaInvalida': (params) => params.message,
    'loginObrigatorio': (params) => params.message,
  };

  @Input()
  private control: AbstractControlDirective | AbstractControl;

  shouldShowErrors_v1(): boolean {
    return this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched);
  }

  shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      (!this.control.invalid || this.control.touched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]));
  }

  private getMessage(type: string, params: any) {
    return ShowErrorsComponent.errorMessages[type](params);
  }


}
