import { NativeDateAdapter } from "@angular/material/core";

export class BrazilianDateAdapter extends NativeDateAdapter {
  private static monthNames = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  override format(date: Date, displayFormat: Object): string {
    let result = null;
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    switch (displayFormat) {
      case "DD/MM/YYYY":
        result = `${day}/${month + 1}/${year}`;
        break;

      case "MMM/YYYY":
        const monthName = BrazilianDateAdapter.monthNames[month];
        result = `${monthName}/${year}`;
        break;

      default:
        result = super.format(date, displayFormat);
    }

    return result;
  }

  override parse(value: string): any {
    let parts = value.split("/");
    if (parts.length == 3) {
      return new Date(+parts[2], +parts[1] - 1, +parts[0]);
    }
  }
}
