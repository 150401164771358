import { FormControl, AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { StringUtils } from '../data-type-utils/string-utils';
import { Injectable } from '@angular/core';

@Injectable()
export class StringValidator {

    static minLength(minLength: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const value: string = control.value ? control.value : '';
            return value.trim().length < minLength ? { 'minlength': { 'requiredLength': minLength, 'actualLength': value.length } } : null;
        };
    }

    static isPresent(obj: any): boolean {
        return obj !== undefined && obj !== null;
    }
}