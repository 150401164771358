import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Sistema } from "./models/constants";
import { ToastrService } from "ngx-toastr";
import { Pages } from '../shared/models/pages';
import { Mensagem } from '../shared/models/mensagem';
import { Usuario } from "../SICSv2/usuario/models/usuario";

@Injectable()
export class AuthService implements CanActivate {
    public token: string;
    public user: Usuario;

    constructor(private router: Router, private toastr: ToastrService) { }

    canActivate(activatedRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        
        this.token = localStorage.getItem(Sistema.localStorage.token);
        this.user = JSON.parse(localStorage.getItem(Sistema.localStorage.user));

        if (!this.token || !this.user) {
            localStorage.clear();
            sessionStorage.clear();
            this.router.navigate([Pages.Usuario.login])
            return false;
        }

        let claim: any = activatedRoute.data[0];
        if (claim !== undefined) {
            let claim = activatedRoute.data[0]['claim'];
            if (claim) {
                if (!this.user.claims)
                    return this.unauthorized();

                let userClaims = this.user.claims.some(x => x.tipo.toUpperCase() === claim.tipo.toUpperCase() && x.valor.toUpperCase() === claim.valor.toUpperCase());

                if (!userClaims)
                    return this.unauthorized();
            }
        }

        this.setupUrlReturn(state.url);
        return true;
    }

    unauthorized() {        
        this.toastr.error(Mensagem.usuarioSemPermissao);
        return false;
    }

    setupUrlReturn(url: string) {
        if (url !== Pages.Usuario.centroCusto)
            localStorage.setItem(Sistema.localStorage.urlReturn, url);
    }
}