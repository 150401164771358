import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./SICSv2/usuario/login/login.component";
import { HomeComponent } from "./SICSv2/home/home.component";
import { AuthService } from "./services/auth.service";
import { CentroCustoComponent } from "./SICSv2/usuario/centro-custo/centro-custo.component";
import { AlterarSenhaComponent } from "./SICSv2/usuario/alterar-senha/alterar-senha.component";
import { EsqueciSenhaComponent } from "./SICSv2/usuario/esqueci-senha/esqueci-senha.component";
import { ConsultarUsuariosAdComponent } from "./SICSv2/consultar-usuarios-ad/consultar-usuarios-ad.component";
import { DesativarUsuariosAdComponent } from "./SICSv2/desativar-usuarios-ad/desativar-usuarios-ad.component";
import { RelatorioUsuariosAdComponent } from "./SICSv2/relatorio-usuarios-ad/relatorio-usuarios-ad.component";
import { VincularGrupoSistemaComponent } from "./SICSv2/vincular-grupo-sistema/vincular-grupo-sistema.component";
import { VincularUsuarioGestorSistemaComponent } from "./SICSv2/vincular-usuario-gestor-sistema/vincular-usuario-gestor-sistema.component";
import { VincularOrgaoUnidadeUsuarioComponent } from "./SICSv2/vincular-orgao-unidade-usuario/vincular-orgao-unidade-usuario.component";
import { AtribuirPermissaoPorAplicacaoComponent } from "./SICSv2/atribuir-permissao-por-aplicacao/atribuir-permissao-por-aplicacao.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "login", component: LoginComponent },
  { path: "home", canActivate: [AuthService], component: HomeComponent },
  {
    path: "centro-de-custo",
    canActivate: [AuthService],
    component: CentroCustoComponent,
  },
  {
    path: "alterar-senha",
    canActivate: [AuthService],
    component: AlterarSenhaComponent,
  },
  {
    path: "esqueci-senha",
    canActivate: [AuthService],
    component: EsqueciSenhaComponent,
  },
  {
    path: "consultar-usuarios-ad",
    canActivate: [AuthService],
    component: ConsultarUsuariosAdComponent,
    data: [{ claim: { tipo: "consultar-usuarios-ad", valor: "visualizar" } }],
  },
  {
    path: "desativar-usuarios-ad",
    canActivate: [AuthService],
    component: DesativarUsuariosAdComponent,
    data: [{ claim: { tipo: "desativar-usuarios-ad", valor: "visualizar" } }],
  },
  {
    path: "relatorio-usuarios-ad",
    canActivate: [AuthService],
    component: RelatorioUsuariosAdComponent,
    data: [
      {
        claim: {
          tipo: "relatorio-desativacao-usuario-ad",
          valor: "visualizar",
        },
      },
    ],
  },
  {
    path: "vincular-grupo-sistema",
    canActivate: [AuthService],
    component: VincularGrupoSistemaComponent,
    data: [{ claim: { tipo: "vincular-grupo-sistema", valor: "visualizar" } }],
  },
  {
    path: "vincular-usuario-gestor-sistema",
    canActivate: [AuthService],
    component: VincularUsuarioGestorSistemaComponent,
    data: [
      {
        claim: { tipo: "vincular-usuario-gestor-sistema", valor: "visualizar" },
      },
    ],
  },
  {
    path: "vincular-usuario-orgao-unidade",
    canActivate: [AuthService],
    component: VincularOrgaoUnidadeUsuarioComponent,
    data: [
      {
        claim: { tipo: "vincular-usuario-orgao-unidade", valor: "visualizar" },
      },
    ],
  },
  {
    path: "atribuir-permissao-acesso",
    canActivate: [AuthService],
    component: AtribuirPermissaoPorAplicacaoComponent,
    data: [
      { claim: { tipo: "atribuir-permissao-acesso", valor: "visualizar" } },
    ],
  },
  { path: "**", pathMatch: "full", redirectTo: "login" },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
