import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { ClickOutsideModule } from "ng-click-outside";
import { MatTableModule } from "@angular/material/table";
import {
  MatPaginatorModule,
  MatPaginatorIntl,
} from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatListModule } from "@angular/material/list";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";

// components
import { MenuSuperiorComponent } from "./menu-superior/menu-superior.component";
import { getPaginatorIntl } from "./models/material-paginator";
import { ConfirmComponent } from "./confirm/confirm.component";
import { AlertComponent } from "./alert/alert.component";
import { ProtocoloPipe } from "../pipes/protocolo.pipe";
import { ShowErrorsComponent } from "../common/show-erros/show-erros.component";
import { RodapeComponent } from "./rodape/rodape.component";
import { DescricaoMaxPipe } from "../pipes/descricao-max.pipe";

@NgModule({
  declarations: [
    ProtocoloPipe,
    DescricaoMaxPipe,
    MenuSuperiorComponent,
    ConfirmComponent,
    AlertComponent,
    ShowErrorsComponent,
    RodapeComponent,
  ],
  imports: [
    NgxMaskDirective,
    NgxMaskPipe,
    NgbModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    NgxSpinnerModule,
    ClickOutsideModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    FormsModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatListModule,
    MatRippleModule,
  ],
  exports: [
    ProtocoloPipe,
    DescricaoMaxPipe,
    MenuSuperiorComponent,
    ConfirmComponent,
    AlertComponent,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    ClickOutsideModule,
    MatAutocompleteModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    FormsModule,
    ShowErrorsComponent,
    MatButtonToggleModule,
    MatIconModule,
    MatTooltipModule,
    MatListModule,
    MatRippleModule,
    RodapeComponent,
  ],
  providers: [
    DatePipe,
    provideNgxMask(),
    NgbModal,
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() },
  ],
})
export class SharedModule {}
