
export const Mensagem = {
    erroGenerico: 'SICSv2 - Ocorreu um erro, tente novamente mais tarde.',
    campoObrigatorio: 'Campo obrigatório.',
    operacaoSucesso: 'Operação efetuada com sucesso.',
    nenhumRegistroEncontrado: 'Nenhum registro encontrado.',
    usuarioSemPermissao: 'Usuário sem permissão.',
    usuarioDesativado: 'Usuário desativado.',
    erroBaixarAnexo: 'Erro ao baixar um dos anexos.',
    erroBaixarRelatorio: 'Erro ao baixar relatório.',
    marcarCiencia: 'É necessário marcar a ciência da ação.',
    verifiqueCampos: 'Verifique o preenchimento dos campos.',
    arquivoNaoPermitido: 'Arquivo não permitido.',
    tamanhoMaxAnexo: 'O tamanho máximo de anexo permitido é ',
    usuarioJaDesativado: 'Este usuário já está desativado.',
    erroAoPesquisar: 'Erro ao pesquisar, por favor, comunique o analista.',
    preencherCamposConsultaUsuarioAd: 'É necessário preencher pelo menos um dos campos: Nome, Matrícula, Login ou Cargo.',
    intervaloDataInvalido: 'A data inicial não pode ser maior do que a data final.',
    loginInvalido: 'Login e/ou senha inválidos.',
    naoAcessaAD: 'Não foi possível conectar no AD.',
    MSE01: 'Confirma a desvinculação do gestor {Matricula - Nome} do sistema {Sigla – Nome completo} ?',
    MSE02: 'Confirma a desvinculação do(s) grupo(s) do sistema {Sistema} ?', 
    MSE03: 'Confirma a desvinculação do usuário {Matricula - Nome} do sistema {Sistema} ?', 
    MSE04: 'Confirma a desvinculação do órgão/unidade {Descricao} do usuário {Nome do usuario - Matricula} ?',
    MSG06: 'Sistema não encontrado. Informe a Sigla do sistema ou o nome.',
    MSG07: 'Usuário não encontrado. Informe a matrícula ou o nome completo.',
    MSG08: 'Grupo não encontrado. Informe a Sigla ou o nome do grupo.',
    MSG10: 'Órgão/Unidade não encontrado',
    //emailEnviadoSucesso: 'E-mail enviado com sucesso.',
    //cpfInvalido: 'O CPF informado é inválido.',
    //nenhumFiltroInformado: 'Informe ao menos um campo.',
    //selecioneUmRegistro: 'Selecione um registro para prosseguir.',
    //selecioneAoMenosUmRegistro: 'Selecione ao menos um registro para realizar esta operação.',
    //confirmacaoExclusao: 'Deseja realmente excluir o(s) registro(s) selecionado(s)?',    
    //registroInexistente: 'Registro referenciado inexistente.',
    //senhaCurta: 'A nova senha deve ter no mínimo 6 caracteres.',
    //arquivoNaoPermitido: 'Operação inválida, o arquivo não está no formato permitido.',
    //orgaoExternoObrigatorio: 'Campo Órgão Externo obrigatório.',
    //confirmacaoEdicao: 'Esse registro é de origem do CNMP, deseja mesmo editá-lo?'
}